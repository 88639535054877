/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Swal from "sweetalert2";
import Blur from "react-blur";
import { commonLogoutFunc } from "../../../utils/share";
import Loader from "../../../layout/loader";
import {
  EditUserName,
  activateOrDeactivateUser,
  getUserDetails,
  getUserPlayDateHistory,
  verifyUser,
} from "../../../services/users.services";
import editIcon from "../../../assets/images/textfeild_edit.png";
import SubmitIcon from "../../../assets/images/textfeild_submit.png";
import EditIcom from "../../../assets/images/Icons/EditIcom";
import TickIcon from "../../../assets/images/Icons/TickIcon";

const UserDetails = () => {
  const [data, setData] = useState({});
  const [interest, setInterest] = useState([]);
  const [coupleInterest, setCoupleInterest] = useState([]);
  const [dealBreaker, setDealBreaker] = useState([]);
  const [coupleDealBreaker, setCoupleDealBreaker] = useState([]);
  const [classification, setClassification] = useState([]);
  const [coupleClassification, setCoupleClassification] = useState([]);
  const [userType, setUserType] = useState([]);
  const [coupleUserType, setCoupleUserType] = useState([]);
  const [openTo, setOpenTo] = useState([]);
  const [coupleOpenTo, setCoupleOpenTo] = useState([]);
  const [playWith, setPlayWith] = useState([]);
  const [couplePlayWith, setCouplePlayWith] = useState([]);
  const [id, setId] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [deactivateReason, setDeactivateReason] = useState("");

  const [show, setShow] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);

  const [doc, setDoc] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldColor, setFieldColor] = useState("#495057");
  const [isLoading, setLoading] = useState(false);
  const [playdates, set_playdates] = useState([]);
  const [isEditable, setIsEditable] = useState({
    firstName1: false,
    lastName1: false,
    firstName2: false,
    lastName2: false,
  });
  const [inputValues, setInputValues] = useState({
    firstName1: "",
    lastName1: "",
    firstName2: "",
    lastName2: "",
  });

  useEffect(() => {
    const userId = params.id;
    setId(userId);
    user_details(userId);
    getPlaydates(userId);
  }, [params.id]);

  useEffect(() => {
    setInputValues({
      firstName1: data.firstName1 || '',
      lastName1: data.lastName1 || '',
      firstName2: data.firstName2 || '',
      lastName2: data.lastName2 || '',
    });
  }, [data.firstName1, data.lastName1, data?.firstName2, data?.lastName2]);

  // Handler to toggle edit mode
  const handleEditClick = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Handler to manage input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const user_details = async (id) => {
    toast.dismiss();
    setLoading(true);
    try {
      const response = await getUserDetails(id);
      setLoading(false);
      setData(response?.data);
      const color = response?.data?.gender === "couple" ? "#FF6700" : "#495057";
      setFieldColor(color);
      const idType = response?.data?.userIdentity?.identity1
        ? response?.data?.userIdentity?.identity1
            .split(".")
            .pop()
            .split("?")?.[0]
        : "";
      setDoc(
        idType !== "jpg" &&
          idType !== "jpeg" &&
          idType !== "png" &&
          idType !== "image"
      );

      const userInterests = response?.data?.userInterests.map((interest) => {
        if (interest.personNum === 1) {
          return interest.interest;
        } else if (interest.personNum === 2) {
          setCoupleInterest((prevInterests) => [
            ...prevInterests,
            interest.interest,
          ]);
          return null;
        }
      });
      setInterest(userInterests.filter((interest) => interest !== null));

      const userDealBreaker = response?.data?.userDealBreaker.map(
        (dealBreaker) => {
          if (dealBreaker.personNum === 1) {
            return dealBreaker.dealBreaker;
          } else if (dealBreaker.personNum === 2) {
            setCoupleDealBreaker((prevInterests) => [
              ...prevInterests,
              dealBreaker.dealBreaker,
            ]);
            return null;
          }
        }
      );
      setDealBreaker(
        userDealBreaker.filter((dealBreaker) => dealBreaker !== null)
      );

      const userClassifications = response?.data?.userClassification.map(
        (classification) => {
          if (classification.personNum === 1) {
            return classification.classification;
          } else if (classification.personNum === 2) {
            setCoupleClassification((prevClassification) => [
              ...prevClassification,
              classification.classification,
            ]);
            return null;
          }
        }
      );
      setClassification(
        userClassifications.filter((classification) => classification !== null)
      );
      const userSelfClassification = response?.data?.userSelfClassification.map(
        (classification) => {
          if (classification.personNum === 1) {
            return classification.selfClassification;
          } else if (classification.personNum === 2) {
            setCoupleUserType((prevClassification) => [
              ...prevClassification,
              classification.selfClassification,
            ]);
            return null;
          }
        }
      );
      setUserType(
        userSelfClassification.filter(
          (classification) => classification !== null
        )
      );

      const userOpenTo = response?.data?.userOpenTo.map((openTo) => {
        if (openTo.personNum === 1) {
          return openTo.openTo;
        } else if (openTo.personNum === 2) {
          setCoupleOpenTo((prevOpenTo) => [...prevOpenTo, openTo.openTo]);
          return null;
        }
      });
      setOpenTo(userOpenTo.filter((openTo) => openTo !== null));

      const userPlayWith = response?.data?.userPlayWith.map((playWith) => {
        if (playWith.personNum === 1) {
          return playWith.playWith;
        } else if (playWith.personNum === 2) {
          setCouplePlayWith((prevOpenTo) => [...prevOpenTo, playWith.playWith]);
          return null;
        }
      });
      setPlayWith(userPlayWith.filter((playWith) => playWith !== null));
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (error.response.status === 404) {
        navigate(-1);
      }
      if (error.response.status === 401) {
        sessionStorage.clear();
        commonLogoutFunc();
        toast.error("Your session has expired. Please login again!");
        return;
      }
      toast.error(error.response.data.error);
    }
  };

  const getPlaydates = async (id) => {
    const response = await getUserPlayDateHistory(id);
    console.log("response", response);
    set_playdates(response.data);
  };

  const handleClose = () => {
    setShow(false);
    setShowDeactivate(false);
  };

  const rejectRequest = () => {
    setShow(true);
    setRejectReason("");
  };

  const deactivateRequest = () => {
    setShowDeactivate(true);
    setDeactivateReason("");
  };

  const handleSubmit = async (field) => {
    const data = {
      firstName1: inputValues?.firstName1,
      lastName1: inputValues?.lastName1,
      firstName2: inputValues?.firstName2,
      lastName2: inputValues?.lastName2,
    };
    try {
      await EditUserName(id, data);
      setIsEditable((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    } catch (e) {
      console.error(e);
    }
  };
  const userVerifyStatus = async (e, status) => {
    e.preventDefault();
    toast.dismiss();
    if (status === "2" && rejectReason.trim() === "") {
      toast.error("Please enter the rejection reason.");
      return false;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        status === "1"
          ? "User will be verified"
          : "User verification request will be rejected",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF6700",
      cancelButtonColor: "#2B2B2B",
      confirmButtonText: status === "1" ? "Verify" : "Reject",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let params;
        if (status === "1") {
          params = {
            action: "accept",
          };
        } else {
          params = {
            action: "reject",
            reason: rejectReason,
          };
        }

        try {
          await verifyUser(id, params);
          setLoading(false);
          Swal.fire({
            title: status === "1" ? "Verified" : "Rejected",
            text:
              status === "1"
                ? "User has been verified successfully."
                : "User verification request has been rejected.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF6700",
          });
          navigate(`/verify_user?page=${1}`, {
            state: { url: location.pathname },
          });
        } catch (error) {
          setLoading(false);
          if (error.response && error.response.status === 401) {
            sessionStorage.clear();
            commonLogoutFunc();
            toast.error("Your session has expired. Please login again!");
            return;
          }
        }
      }
    });
  };

  const userStatus = (e, status) => {
    e.preventDefault();
    toast.dismiss();
    if (status === "2" && deactivateReason.trim() === "") {
      toast.error("Please enter the deactivation reason.");
      return false;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        status === "1" ? "User will be activated" : "User will be deactivated",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF6700",
      cancelButtonColor: "#2B2B2B",
      confirmButtonText: status === "1" ? "Activate" : "Deactivate",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let params;
        if (status === "1") {
          params = {
            action: "activate",
          };
        } else {
          params = {
            action: "deactivate",
            reason: deactivateReason,
          };
        }

        try {
          await activateOrDeactivateUser(id, params);
          setLoading(false);
          Swal.fire({
            title: status === "1" ? "Activate" : "Deactivate",
            text:
              status === "1"
                ? "User has been activated successfully."
                : "User has been deactivated.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF6700",
          });
          navigate(-1);
        } catch (error) {
          setLoading(false);
          if (error.response && error.response.status === 401) {
            sessionStorage.clear();
            commonLogoutFunc();
            toast.error("Your session has expired. Please login again!");
            return;
          }
        }
      }
    });
  };

  return (
    <section className="botmanagement_section">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="transaction_box mb-3 new_Itinerary">
              <Loader promiseInProgress={isLoading} />
              <Row>
                <Col className="text-center">
                  <h3>User Details</h3>
                  {data.userProfileStatus?.status === "VERIFIED" && (
                    <div className="togglebtn text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="btn-sus-1">
                            {data.isActive
                              ? "Deactivate User"
                              : "Activate User"}
                          </Tooltip>
                        }
                      >
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.isActive}
                            onClick={(e) => {
                              if (!data.isActive) {
                                userStatus(e, "1");
                              } else {
                                deactivateRequest();
                              }
                            }}
                          />

                          <span className="slider round"></span>
                        </label>
                      </OverlayTrigger>
                    </div>
                  )}
                  {data.userProfileStatus?.status === "UNVERIFIED" &&
                    location.pathname.includes("/unverified_user") && (
                      <div className="togglebtn text-right">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="btn-sus-1">Verify User</Tooltip>
                          }
                        >
                          <Button
                            className="btn-orange mr-2"
                            onClick={(e) => userVerifyStatus(e, "1")}
                            variant="unset"
                          >
                            Verify
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="btn-sus-1">Reject Request</Tooltip>
                          }
                        >
                          <Button
                            onClick={() => rejectRequest()}
                            className="btn-black"
                            variant="unset"
                          >
                            Reject
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form className="text-left">
                    <Row>
                      <Col md={12} className="mb-3">
                        <Row className="idetify-images">
                          <Col md={3}>
                            <h5>PROFILE</h5>
                            {data.userIdentity?.profilePhoto ? (
                              <Zoom zoomMargin={150}>
                                <img
                                  className="user-image"
                                  height="300"
                                  src={data.userIdentity?.profilePhoto}
                                  alt=""
                                />
                              </Zoom>
                            ) : (
                              <img
                                className="user-image"
                                height="220"
                                src={require("../../../assets/images/profile.png")}
                                alt=""
                              />
                            )}
                          </Col>
                          <Col md={3}>
                            <h5>SELFIE</h5>
                            {data.userIdentity?.selfie1 ? (
                              <Zoom zoomMargin={150}>
                                <img
                                  className={
                                    data.gender === "couple"
                                      ? `couple-image`
                                      : `user-image`
                                  }
                                  height="300"
                                  src={data.userIdentity?.selfie1}
                                  alt=""
                                />
                              </Zoom>
                            ) : (
                              <img
                                className={
                                  data.gender === "couple"
                                    ? `couple-image`
                                    : `user-image`
                                }
                                height="220"
                                src={require("../../../assets/images/profile.png")}
                                alt=""
                              />
                            )}
                          </Col>
                          <Col md={3}>
                            <h5>ID</h5>
                            {data.userIdentity?.identity1 ? (
                              // doc ?
                              //     <OverlayTrigger
                              //         overlay={
                              //             <Tooltip id="btn-sus-1">View Document</Tooltip>
                              //         }>
                              //         <img className={data.gender === 'couple' ? `couple-image` : "user-image"} onClick={() => window.open(data.userIdentity?.identity1, '_blank').focus()} src={require('../../../assets/images/doc.svg').default} alt="" />
                              //     </OverlayTrigger>
                              //     :
                              <Zoom zoomMargin={150}>
                                <img
                                  className={
                                    data.gender === "couple"
                                      ? `couple-image`
                                      : `user-image`
                                  }
                                  src={data.userIdentity?.identity1}
                                  height="300"
                                  alt=""
                                />
                              </Zoom>
                            ) : (
                              <h6 className="user-identify">
                                No identification document available.
                              </h6>
                            )}
                          </Col>
                          {data.gender === "couple" && (
                            <>
                              <Col md={3}>
                                <h5>SELFIE</h5>
                                {data.userIdentity?.selfie2 ? (
                                  <Zoom zoomMargin={150}>
                                    <img
                                      className={
                                        data.gender === "couple"
                                          ? `couple-image-user`
                                          : `user-image`
                                      }
                                      height="300"
                                      src={data.userIdentity?.selfie2}
                                      alt=""
                                    />
                                  </Zoom>
                                ) : (
                                  <img
                                    className={
                                      data.gender === "couple"
                                        ? `couple-image-user`
                                        : `user-image`
                                    }
                                    height="220"
                                    src={require("../../../assets/images/profile.png")}
                                    alt=""
                                  />
                                )}
                              </Col>
                              <Col md={3}>
                                <h5>ID</h5>
                                {data.userIdentity?.identity2 ? (
                                  // doc ?
                                  //     <OverlayTrigger
                                  //         overlay={
                                  //             <Tooltip id="btn-sus-1">View Document</Tooltip>
                                  //         }>
                                  //         <img className={data.gender === 'couple' ? `couple-image-user` : "doc-identity"} onClick={() => window.open(data.userIdentity?.identity2, '_blank').focus()} src={require('../../../assets/images/doc.svg').default} alt="" />
                                  //     </OverlayTrigger>
                                  //     :
                                  <Zoom zoomMargin={150}>
                                    <img
                                      className={
                                        data.gender === "couple"
                                          ? `couple-image-user`
                                          : `user-image`
                                      }
                                      src={data.userIdentity?.identity2}
                                      height="300"
                                      alt=""
                                    />
                                  </Zoom>
                                ) : (
                                  <h6 className="user-identify">
                                    No identification document available.
                                  </h6>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type="text"
                            className="disabled-fields"
                            value={data.userName}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {data.email && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              className="disabled-fields"
                              value={data.email}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      {data.firstName1 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              name="firstName1"
                              value={inputValues.firstName1}
                              className={
                                location.pathname === `/unverified_user/${id}`
                                  ? ""
                                  : "disabled-fields"
                              }
                              disabled={!isEditable.firstName1}
                              onChange={handleInputChange}
                            />
                            {location.pathname === `/unverified_user/${id}` ? (
                              <div
                                className="textfeild-edit-icon"
                                onClick={() =>
                                  isEditable.firstName1
                                    ? handleSubmit("firstName1")
                                    : handleEditClick("firstName1")
                                }
                              >
                                {isEditable.firstName1 ? (
                                  <TickIcon />
                                ) : (
                                  <EditIcom />
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Col>
                      )}
                      {data.lastName1 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              name="lastName1"
                              value={inputValues.lastName1}
                              className={
                                location.pathname === `/unverified_user/${id}`
                                  ? ""
                                  : "disabled-fields"
                              }
                              disabled={!isEditable.lastName1}
                              onChange={handleInputChange}
                            />
                            {location.pathname === `/unverified_user/${id}` ? (
                              <div
                                className="textfeild-edit-icon"
                                onClick={() =>
                                  isEditable.lastName1
                                    ? handleSubmit("lastName1")
                                    : handleEditClick("lastName1")
                                }
                              >
                                {isEditable.lastName1 ? (
                                  <TickIcon />
                                ) : (
                                  <EditIcom />
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Col>
                      )}
                      {data.dateOfBirth1 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              className="disabled-fields"
                              value={data.dateOfBirth1}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      {data.phoneNumber1 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              className="disabled-fields"
                              value={data.phoneNumber1}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      {data.dateOfBirth1 !== "" && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              className="disabled-fields"
                              value={moment().diff(
                                moment(data.dateOfBirth1, "DD-MM-YYYY"),
                                "years"
                              )}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Gender</Form.Label>
                          <Form.Control
                            type="text"
                            className="disabled-fields"
                            value={data.gender}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Eye Colour</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.eyeColor1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Hair Colour</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.hairColor1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Body Type</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.bodyType1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Drinking</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.drinking1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Smoking</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.smoking1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Drugs</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.drugs1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {data.eggplantSizeInCm1 && data.gender !== "woman" ? (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Eggplant Size</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              className="disabled-fields"
                              value={data.eggplantSizeInCm1}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {data.melonSize1 && data.gender !== "man" ? (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Melon Size</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              type="text"
                              className="disabled-fields"
                              value={data.melonSize1}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Relationship Status</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.relationshipStatus1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Sexual Orientation</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.sexualOrientation1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Kinky</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.kinkDuration1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Age Preference</Form.Label>
                          <Form.Control
                            type="text"
                            className="disabled-fields"
                            value={
                              data.minPartnerAge + "-" + data.maxPartnerAge
                            }
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Height</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.heightInCm1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Living Arrangement</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            type="text"
                            className="disabled-fields"
                            value={data.livingArrangement1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {data.suburb1 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ color: fieldColor }}
                              className="disabled-fields"
                              value={`${
                                data.unitNumber1 ? data.unitNumber1 + "," : ""
                              } ${data.streetNumber1}, ${data.streetName1}, ${
                                data.suburb1
                              }, ${data.state1}, ${data.postCode1}`}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Grooming</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={data.grooming1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              as="textarea"
                              className="disabled-fields"
                              value={userType}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      }
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Wants to play with</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={classification}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Open To</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={openTo}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Like To Play With</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={playWith}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Lover Type Description</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={data.loverPhrase1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Self Description</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={data.personTypePhrase1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Interested In</Form.Label>
                          <Form.Control
                            style={{ color: fieldColor }}
                            as="textarea"
                            className="disabled-fields"
                            value={interest}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {dealBreaker.length > 0 && (
                        <Col md={3}>
                          <Form.Group className="mb-1">
                            <Form.Label>Deal Breaker</Form.Label>
                            <Form.Control
                              style={{ color: fieldColor }}
                              as="textarea"
                              className="disabled-fields"
                              value={dealBreaker}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}

                      {data.gender === "couple" && (
                        <>
                          {data.firstName2 && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  value={inputValues?.firstName2}
                                  name="firstName2"
                                  className={
                                    location.pathname === `/unverified_user/${id}`
                                      ? ""
                                      : "disabled-fields"
                                  }
                                  disabled={!isEditable?.firstName2}
                                  onChange={handleInputChange}
                                />
                                {location.pathname === `/unverified_user/${id}` ? (
                                  <div
                                    className="textfeild-edit-icon"
                                    onClick={() =>
                                      isEditable.firstName2
                                        ? handleSubmit("firstName2")
                                        : handleEditClick("firstName2")
                                    }
                                  >
                                    {isEditable.firstName2 ? (
                                      <TickIcon />
                                    ) : (
                                      <EditIcom />
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </Col>
                          )}
                          {data.lastName2 && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  name="lastName2"
                                  value={inputValues.lastName2}
                                  className={
                                    location.pathname === `/unverified_user/${id}`
                                      ? ""
                                      : "disabled-fields"
                                  }
                                  disabled={!isEditable.lastName2}
                                  onChange={handleInputChange}
    
                                />
                                {location.pathname === `/unverified_user/${id}` ? (
                                  <div
                                    className="textfeild-edit-icon"
                                    onClick={() =>
                                      isEditable.lastName2
                                        ? handleSubmit("lastName2")
                                        : handleEditClick("lastName2")
                                    }
                                  >
                                    {isEditable.lastName2 ? (
                                      <TickIcon />
                                    ) : (
                                      <EditIcom />
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </Col>
                          )}
                          {data.dateOfBirth2 && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  className="disabled-fields"
                                  value={data.dateOfBirth2}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          )}
                          {data.phoneNumber2 && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  className="disabled-fields"
                                  value={data.phoneNumber2}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          )}
                          {data.dateOfBirth2 !== "" && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Age</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  className="disabled-fields"
                                  value={moment().diff(
                                    data.dateOfBirth2,
                                    "years"
                                  )}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Eye Colour</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.eyeColor2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Hair Colour</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.hairColor2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Body Type</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.bodyType2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Drinking</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.drinking2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Smoking</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.smoking2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Drugs</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.drugs2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          {data.eggplantSizeInCm2 ? (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Eggplant Size</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  className="disabled-fields"
                                  value={data.eggplantSizeInCm2}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          ) : null}
                          {data.melonSize2 ? (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Melon Size</Form.Label>
                                <Form.Control
                                  style={{ color: "#B79027" }}
                                  type="text"
                                  className="disabled-fields"
                                  value={data.melonSize2}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          ) : null}
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Relationship Status</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.relationshipStatus2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Sexual Orientation</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.sexualOrientation2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Kinky</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.kinkDuration2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Height</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.heightInCm2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Living Arrangement</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                type="text"
                                className="disabled-fields"
                                value={data.livingArrangement2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          {data.suburb2 && (
                            <Col md={3}>
                              <Form.Group className="mb-1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  style={{ color: "#B79027" }}
                                  className="disabled-fields"
                                  value={`${
                                    data.unitNumber2
                                      ? data.unitNumber2 + ","
                                      : ""
                                  } ${data.streetNumber2}, ${
                                    data.streetName2
                                  }, ${data.suburb2}, ${data.state2}, ${
                                    data.postCode2
                                  }`}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Grooming</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={data.grooming2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>User Type</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={coupleUserType}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Wants to play with</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={coupleClassification}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Open To</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={coupleOpenTo}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Like To Play With</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={couplePlayWith}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Lover Type Description</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={data.loverPhrase2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Self Description</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={data.personTypePhrase2}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Interested In</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={coupleInterest}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Deal Breaker</Form.Label>
                              <Form.Control
                                style={{ color: "#B79027" }}
                                as="textarea"
                                className="disabled-fields"
                                value={coupleDealBreaker}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Joined At</Form.Label>
                          <Form.Control
                            type="text"
                            className="disabled-fields"
                            value={moment(data.createdAt).format("YYYY-MM-DD")}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Account Verified</Form.Label>
                          <Form.Control
                            type="text"
                            className="disabled-fields"
                            value={
                              data.userProfileStatus?.status === "VERIFIED"
                                ? "Yes"
                                : "No"
                            }
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      {data.userProfileStatus?.status === "VERIFIED" && (
                        <>
                          <Col md={3}>
                            <Form.Group className="mb-1">
                              <Form.Label>Membership Status</Form.Label>
                              <Form.Control
                                type="text"
                                className="disabled-fields"
                                name="plan"
                                value={
                                  data?.subscription
                                    ? data.subscription?.plan
                                    : "Free"
                                }
                                disabled
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                      {data.userProfileStatus?.status === "VERIFIED" && (
                        <>
                          <Col md={12}>
                            <h5 className="mt-2">Public Gallery Images</h5>
                            <div className="imgContainer">
                              {data?.userPublicGallery.length > 0 ? (
                                data?.userPublicGallery?.map((images) => (
                                  <div>
                                    <div className="private-gallery maingarry">
                                      <div className="private-photos">
                                        <Zoom zoomMargin={150}>
                                          {images.blurValue === 0 ? (
                                            <img
                                              className="imgsize"
                                              src={images.imageUrl}
                                              alt=""
                                            />
                                          ) : (
                                            <Blur
                                              img={images.imageUrl}
                                              blurRadius={images.blurValue}
                                            />
                                          )}
                                        </Zoom>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <h6 className="user-identify text-left">
                                  none uploaded
                                </h6>
                              )}
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5 className="mt-2">Private Gallery Images</h5>
                            {data?.userPrivateGallery.length > 0 ? (
                              <>
                                {data.userPrivateGallery
                                  .reduce((groups, image) => {
                                    if (!groups[image.galleryNumber]) {
                                      groups[image.galleryNumber] = [];
                                    }
                                    groups[image.galleryNumber].push(image);
                                    return groups;
                                  }, [])
                                  .map((galleryImages, index) => (
                                    <div className="mt-2" key={index}>
                                      <h6>Private Gallery {index}</h6>
                                      <div className="private-gallery maingarry">
                                        {galleryImages.map((photo) => (
                                          <div
                                            className="private-photos"
                                            key={photo.id}
                                          >
                                            <Zoom zoomMargin={150}>
                                              {photo.blurValue === 0 ? (
                                                <img
                                                  className="imgsize"
                                                  src={photo.imageUrl}
                                                  alt=""
                                                />
                                              ) : (
                                                <Blur
                                                  img={photo.imageUrl}
                                                  blurRadius={photo.blurValue}
                                                />
                                              )}
                                            </Zoom>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <h6 className="user-identify text-left">
                                none uploaded
                              </h6>
                            )}
                          </Col>
                          <Col md={12} className="mt-2">
                            <h5>Playdates History</h5>
                            <div className="table_box text-center">
                              {playdates.length > 0 ? (
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Username</th>
                                      <th>Name</th>
                                      <th>Phone Number</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {playdates.map((play) => {
                                      return (
                                        <tr>
                                          <td>
                                            {moment(play.createdTime).format(
                                              "YYYY-MM-DD HH:MM A"
                                            )}
                                          </td>
                                          <td>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="btn-view-1">
                                                  View User Details
                                                </Tooltip>
                                              }
                                            >
                                              <h6
                                                className="contact-name"
                                                onClick={() =>
                                                  navigate(
                                                    `/user_details/${play.id}`
                                                  )
                                                }
                                              >
                                                {play.userName}
                                              </h6>
                                            </OverlayTrigger>
                                          </td>
                                          <td>
                                            {play.firstName +
                                              " " +
                                              play.lastName}
                                          </td>
                                          <td>{play.phoneNumber}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : (
                                <h6 className="user-identify text-left">
                                  none registered
                                </h6>
                              )}
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={() => handleClose()}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Reject Verification Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>Reason for rejecting request</Modal.Body>
          <Form>
            <Form.Group className="ml-2">
              <Form.Control
                style={{ maxWidth: "480px" }}
                as="textarea"
                rows={5}
                placeholder="Enter rejection reason"
                maxLength={500}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
            <Button
              className="btn-black"
              variant="unset"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="btn-orange"
              variant="unset"
              onClick={(e) => userVerifyStatus(e, "2")}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeactivate}
          onHide={() => handleClose()}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Deactivate User</Modal.Title>
          </Modal.Header>
          <Modal.Body>Reason for deactivating user</Modal.Body>
          <Form>
            <Form.Group className="ml-2">
              <Form.Control
                style={{ maxWidth: "480px" }}
                as="textarea"
                rows={5}
                placeholder="Enter deactivation reason"
                maxLength={350}
                value={deactivateReason}
                onChange={(e) => setDeactivateReason(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
            <Button
              className="btn-black"
              variant="unset"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="btn-orange"
              variant="unset"
              onClick={(e) => userStatus(e, "2")}
            >
              Deactivate
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default UserDetails;
