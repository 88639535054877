import React from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SwitchButton({
  label = "",
  value = false,
  handleChange = () => {},
}) {
  const Android12Switch = styled(Switch)(() => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      background: '#CCCCCC',
      opacity: 1,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#FF6700 !important",
      opacity: "1 !important",
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "white",
    },
  }));

  return (
    <FormControlLabel
      control={
        <Android12Switch checked={value} onChange={(e) => handleChange(e.target.checked)} />
      }
      label={label}
    />
  );
}
