/* eslint-disable import/no-anonymous-default-export */
import {
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  PROFILE_FULFILLED,
  PROFILE_PENDING,
  PROFILE_REJECTED,
} from './loginActionTypes';

const initialState = {
  showSidebar: true
};
const loginPending = (state) => ({
  ...state,
  status: "loading"
});
const loginFulfilled = (state, payload) => {
  let { payload: payloadData } = payload;
  let user = payloadData?.data?.data?.admin;
  let token = payloadData?.data?.data?.token;
  return ({
    ...state,
    user,
    token,
    status: "success"
  })
};
const loginRejected = (state) => ({
  ...state,
  status: "error"
});

const profilePending = (state) => ({
  ...state,
  profile: 'loading',
});
const profileFulfilled = (state, payload) => {
  let { payload: payloadData } = payload;
  let user = payloadData?.data?.data?.admin;
  return {
    ...state,
    user: user,
    profile: 'success',
    lastUpdated: new Date().getTime(),
  };
};
const profileRejected = (state) => ({
  ...state,
  user: null,
  profile: 'error',
});

const toggleSidebar = (state) => ({
  ...state,
  showSidebar: state?.showSidebar ? false : true,
});


export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_PENDING:
      return loginPending(state, action);

    case LOGIN_FULFILLED:
      return loginFulfilled(state, action);

    case LOGIN_REJECTED:
      return loginRejected(state, action);

    case PROFILE_PENDING:
      return profilePending(state, action);

    case PROFILE_FULFILLED:
      return profileFulfilled(state, action);

    case PROFILE_REJECTED:
      return profileRejected(state, action);

    case "TOGGLE_SIDEBAR":
      return toggleSidebar(state);

    default:
      return state;
  }
}
