import axios from 'axios';
import { axiosInstance } from '../config/api';

export const fileUpload = async (file, key) => {

    const url = `/image-upload/profilePhoto/${key}`;
    let axiosConfig = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': file.type,
        },

    };
    const reponse = await axiosInstance.get(url)
    const fileUploadData = reponse?.data?.data;
    await axios.put(fileUploadData.apiUrl, file, axiosConfig);
    return fileUploadData

};
