import React from "react";

const EditIcom = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10521_7118)">
        <path
          d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
          fill="white"
        />
        <path
          d="M5.5 12.73V14.25C5.5 14.39 5.61 14.5 5.75 14.5H7.27C7.335 14.5 7.4 14.475 7.445 14.425L12.905 8.97L11.03 7.095L5.575 12.55C5.525 12.6 5.5 12.66 5.5 12.73ZM14.355 7.52C14.4014 7.47374 14.4381 7.4188 14.4632 7.35831C14.4883 7.29783 14.5012 7.23299 14.5012 7.1675C14.5012 7.10202 14.4883 7.03718 14.4632 6.97669C14.4381 6.9162 14.4014 6.86126 14.355 6.815L13.185 5.645C13.1387 5.59865 13.0838 5.56188 13.0233 5.53679C12.9628 5.51169 12.898 5.49878 12.8325 5.49878C12.767 5.49878 12.7022 5.51169 12.6417 5.53679C12.5812 5.56188 12.5263 5.59865 12.48 5.645L11.565 6.56L13.44 8.435L14.355 7.52Z"
          fill="#2B2B2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_10521_7118">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcom;
