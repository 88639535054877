import { axiosInstance } from '../config/api';

export const getKarmaBitchList = (year, month, page, limit) => {
    let url = '/karma/score?';
    url += `year=${year}&month=${month}&page=${page - 1 ?? 0}&limit=${limit ?? 10}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};

export const publishKarmaScoreList = () => {
    const url = '/karma/publish';
    return new Promise((resolve, reject) => {
        axiosInstance
            .post(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};

export const sendCongrats = (id, message) => {
    const url = `/karma/congrats/${id}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .post(url, message)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};