import axios from "axios";
import { ERRORS, NOTIFICATION } from "../constants";
import { pushNotification } from "../utils/notification";
import { commonLogoutFunc } from "../utils/share";

export const axiosInstance = axios.create({
  // baseURL: 'http://127.0.0.1:8083/rest/',
  // baseURL: "https://app.unlckedapp.com/rest/",
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://54.153.151.166:8083/rest/',
});

axiosInstance.interceptors.request.use(
  async function (config) {
    try {
      let redux = JSON.parse(localStorage.getItem("redux"));
      const token = redux.login?.token
        ? `Bearer ${redux.login?.token}`
        : undefined;
      config.headers.Authorization = token;
      return config;
    } catch (error) {
      pushNotification(ERRORS.NETWORK_ERROR, NOTIFICATION.ERROR);
      commonLogoutFunc();
      return null;
    }
  },
  function (error) {
    if (error?.response?.data?.error && error?.response?.data?.message) {
      pushNotification(error?.response?.data?.message, NOTIFICATION.ERROR);
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response?.data && response?.data?.error) {
      pushNotification(response?.data?.message, NOTIFICATION.ERROR);
      return Promise.reject(response);
    }
    return response;
  },
  function (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    if (!error?.response?.data) {
      pushNotification(error.message, NOTIFICATION.ERROR);
      if (error.message !== "Network Error") commonLogoutFunc();
    } else if (
      error?.response?.status === 440 ||
      error?.response?.status === 401
    ) {
      pushNotification(ERRORS.SESSION_EXPIRED_ERROR, NOTIFICATION.ERROR);
      commonLogoutFunc();
    } else if (error?.response?.data?.error && error?.response?.data?.message) {
      commonLogoutFunc();
      pushNotification(error?.response?.data?.message, NOTIFICATION.ERROR);
    }
    return Promise.reject(error);
  }
);
