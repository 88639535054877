import React from "react";
import { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../../../assets/images/Logo.png';
import Loader from '../../../layout/loader';
import { pushNotification } from "../../../utils/notification";
import { NOTIFICATION } from "../../../constants";
import './resetPassword.css';
import { useLocation } from "react-router-dom";
import axios from "axios";
const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('New Password is required').min(8),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});


const ResetPassword = () => {
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const handleSubmit = async (values) => {
        try {
            const encodedEmail = searchParams.get("email");
            // Decode the email parameter value
            const email = decodeURIComponent(encodedEmail);
            const token = searchParams.get("token");
            setLoading(true);
            const data = {
                email: email,
                token: token,
                password: values.newPassword
            }
            const loginResp = await axios.post("https://app.unlckedapp.com/rest/auth/user/reset-password", data);
            pushNotification(loginResp?.data?.message, NOTIFICATION.SUCCESS);
        } catch (e) {
            pushNotification(e?.response?.data?.message, NOTIFICATION.ERROR);

        } finally {
            setLoading(false);
            formik.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    return (

        <div className="reset-password-page">
            <div className="header">
                <img src="" alt="" />
            </div>
            <Loader promiseInProgress={isLoading} />
            <section className='reset-password' id='login'>
                <img src={logo} alt="logo" className="reset-password-logo" />
                <div className='form'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="password"
                                id="newPassword"
                                placeholder="New password"
                                required
                                {...formik.getFieldProps('newPassword')}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <div className="error-message">{formik.errors.newPassword}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <input
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm password"
                                required
                                {...formik.getFieldProps('confirmPassword')}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className="error-message">{formik.errors.confirmPassword}</div>
                            ) : null}
                        </div>

                        <button className="btn-reset-password" type="submit">Submit</button>
                    </form>
                </div>
            </section>
            <div className="footer"></div>
        </div>
    );

}

export default ResetPassword;
