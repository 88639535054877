import React, { useState } from "react";
import "./textFeild.css";

const Textfield = ({
  parentClass = "",
  fieldWrapClass = "",
  label = "",
  labelClass = "",
  isRequired = false,
  type,
  placeholder = "",
  name = "",
  inputClass = "",
  value,
  disabled = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  onKeyPress = () => null,
  digitsOnly = null,
  helperText = "",
  error = false,
  endIcon = "",
  startIcon = "",
  inputProps = {},
  rows = "",
  maxLength = 100,
  autoComplete,
  onClick = () => null,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const handleFocus = () => {
    setIsFocus(true);
    onFocus();
  };
  const handleBlur = () => {
    setIsFocus(false);
    onBlur();
  };
  const handleKeyDown = (event) => {
    if (
      !(
        event.key === "Backspace" ||
        event.key === "Delete" ||
        (event.key >= "0" && event.key <= "9")
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className={`textfield-parent ${parentClass}`}>
      {label && (
        <div className="textfield-label-container">
          <label className={`textfield-label ${labelClass}`} htmlFor={name}>
            {label}
          </label>
          {isRequired && <span className="textfield-required">*</span>}
        </div>
      )}
      <div
        className={`textfield-wrapper ${
          isFocus && !error ? "textfield-focus" : ""
        } ${error ? "textfield-error" : ""} ${
          disabled ? "textfield-disabled" : ""
        } ${fieldWrapClass}`}
      >
        {startIcon && (
          <div className="textfield-icon textfield-icon-start">{startIcon}</div>
        )}
        {rows ? (
          <textarea
            {...inputProps}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            className={`textfield-input textfield-textarea ${inputClass}`}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            maxLength={maxLength}
            onKeyPress={onKeyPress}
            disabled={disabled}
            autoComplete={autoComplete || "on"}
            rows={rows || 1}
          />
        ) : (
          <input
            {...inputProps}
            id={name}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            className={`textfield-input ${inputClass}`}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyPress={onKeyPress}
            disabled={disabled}
            autoComplete={autoComplete || "on"}
            maxLength={maxLength}
            onKeyDown={digitsOnly && handleKeyDown}
            onClick={onClick}
          />
        )}
        {rows && maxLength && (
          <span className="textfield-counter">
            {value ? `${value.length}/${maxLength}` : `Max. ${maxLength} words`}
          </span>
        )}
        {endIcon && (
          <div className="textfield-icon textfield-icon-end">{endIcon}</div>
        )}
      </div>
      {error && <p className="textfield-helpertext">{helperText}</p>}
    </div>
  );
};

export default Textfield;
