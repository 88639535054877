/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GetUserCount } from "../services/users.services";

const Sidebar = (props) => {
  const [countData, setCountData] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const showSidebar = useSelector((state) => state?.login?.showSidebar);
  const staticRole = useSelector((state) => state?.login?.user?.staticRole)
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
    toggleSidebar();
    document
      .getElementById("user_manage")
      .setAttribute("aria-expanded", "false");
    document.getElementById("userManagement").className = "collapse sub-list";
  };

  const getUsersCount = async () => {
    const {
      data: { data },
    } = await GetUserCount();
    setCountData(data);
  };
  useEffect(() => {
    getUsersCount();
  }, []);

  useEffect(() => {
    const userManageElement = document.getElementById("user_manage");

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "aria-expanded") {
          const isExpanded =
            userManageElement.getAttribute("aria-expanded") === "true";
          if (isExpanded) {
            setIsDropdownOpen(true);
          } else {
            setIsDropdownOpen(false);
          }
        }
      });
    });

    observer.observe(userManageElement, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    staticRole === 1 ? <section className={`sidebar ${showSidebar ?? true ? "active" : ""}`}>
      <div
        id="abc"
        className={
          path === "/" ||
          path === "/deleted-users" ||
          path === "/found_my_match" ||
          path === "/found_my_person_elsewhere" ||
          path === "/people_still_play_games" ||
          path === "/need_to_be_alone" ||
          path === "/dont_like_app" ||
          path === "/deleted_by_admin"
            ? "active"
            : ""
        }
        onClick={() => {
          navigate("/");
          closeDropdown();
        }}
      >
        <span className="sidebar_option">DASHBOARD</span>
      </div>
      <div>
        <span
          className={
            path === "/user_management" ||
            path.includes("/user_details") ||
            path === "/verify_user" ||
            path.includes("/unverified_user") ||
            path === "/incomplete_profiles" ||
            path.includes("/incomplete_user")
              ? "sidebar_option active"
              : "sidebar_option"
          }
        >
          <a
            id="user_manage"
            href="#userManagement"
            data-toggle="collapse"
            aria-expanded="false"
          >
            {isDropdownOpen ? `USERS (${countData?.totalCount})` : "USERS"}
            <span className="down-arrow-side">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        </span>
        <ul className="collapse list-unstyled sub-list" id="userManagement">
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/user_management?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/user_management" || path.includes("/user_details")
                  ? "active"
                  : ""
              }
            >
              {" "}
              verified ({countData?.verified})
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/verify_user?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/verify_user" || path.includes("/unverified_user")
                  ? "active"
                  : ""
              }
            >
              unverified ({countData?.unverified})
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/incomplete_profiles?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/incomplete_profiles" ||
                path.includes("/incomplete_user")
                  ? "active"
                  : ""
              }
            >
              incomplete ({countData?.incomplete})
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/deactive_user?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/deactive_user" || path.includes("/deactive_user")
                  ? "active"
                  : ""
              }
            >
              deactivated ({countData?.deactive})
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/deleted-users?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/deleted-users" || path.includes("/deleted-users")
                  ? "active"
                  : ""
              }
            >
              deleted ({countData?.totalDeleteUsers})
            </a>
          </li>
        </ul>
      </div>
      <div
        className={path === "/admin" || path.includes("/admin") ? "active" : ""}
        onClick={() => {
          navigate(`/admin?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">ADMIN</span>
      </div>
      <div
        className={
          path === "/gosspel" || path.includes("/user_comments") ? "active" : ""
        }
        onClick={() => {
          navigate(`/gosspel`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">GOSS’PEL</span>
      </div>
      <div
        className={path === "/karma" ? "active" : ""}
        onClick={() => {
          navigate(`/karma?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">KARMA</span>
      </div>
      <div
        className={
          path === "/app_communication" ||
          path.includes("/app_communication") ||
          path.includes("/users_comment_details") ||
          path.includes("/users_response")
            ? "active"
            : ""
        }
        onClick={() => {
          navigate(`/app_communication?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option text-uppercase">
          In-App communication
        </span>
      </div>
      <div
        className={path === "/contact" ? "active" : ""}
        onClick={() => {
          navigate(`/contact?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">PRESS OR COLLAB</span>
      </div>
      <div
        className={path === "/promo" ? "active" : ""}
        onClick={() => {
          navigate(`/promo?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">PROMO CODES</span>
      </div>
      <div
        className={path === "/registered_users" ? "active" : ""}
        onClick={() => {
          navigate(`/registered_users?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">PRE-REGISTERED USERS</span>
      </div>
      <div
        className={path === "/law_enforce" ? "active" : ""}
        onClick={() => {
          navigate(`/law_enforce?page=${1}`);
          closeDropdown();
        }}
      >
        <span className="sidebar_option">LAW ENFORCEMENT</span>
      </div>
    </section> : <section className={`sidebar ${showSidebar ?? true ? "active" : ""}`}>
      <div>
        <span
          className={
            path === "/user_management" ||
            path.includes("/user_details") ||
            path === "/verify_user" ||
            path.includes("/unverified_user") ||
            path === "/incomplete_profiles" ||
            path.includes("/incomplete_user")
              ? "sidebar_option active"
              : "sidebar_option"
          }
        >
          <a
            id="user_manage"
            href="#userManagement"
            data-toggle="collapse"
            aria-expanded="false"
          >
            {isDropdownOpen ? `USERS (${countData?.totalCount})` : "USERS"}
            <span className="down-arrow-side">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        </span>
        <ul className="collapse list-unstyled sub-list" id="userManagement">
          <li>
            <a
              onClick={() => {
                closeDropdown();
                navigate(`/verify_user?page=${1}`, {
                  state: { url: location.pathname },
                });
              }}
              className={
                path === "/verify_user" || path.includes("/unverified_user")
                  ? "active"
                  : ""
              }
            >
              Unverified User ({countData?.unverified})
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Sidebar;
