/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../layout/loader";
import {
  getAlLawEnforcement,
  updateLawEnforcementStatus,
} from "../../../services/contact.services";
// import { commonLogoutFunc } from "../../../utils/share";
import { toast } from "react-toastify";
import moment from "moment";

export const LawEnforce = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [query] = useSearchParams();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({ sub: "", msg: "" });
  const [imageModal, setImageModal] = useState(false);
  const [imageDoc, setImageDoc] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    lawEnforce(page, search);
    if (query !== "" && query.get("page") !== "") {
      var current_page = query.get("page");
      lawEnforce(current_page, search);
      setPage(parseInt(current_page));
    } else {
      navigate(`/law_enforce?page=${1}`);
    }
  }, [query]);

  const lawEnforce = async (page, search) => {
    setLoading(true);
    try {
      const response = await getAlLawEnforcement(page, 10, search);
      setLoading(false);
      setData(response?.data?.data?.records);
      setCount(response?.data?.data?.totalCount);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const viewMessage = (msg) => {
    setShow(true);
    setMessage({ sub: msg.subject, msg: msg.message });
  };

  const handleSearch = (s) => {
    setSearch(s.trimStart());
    if (s === "") {
      navigate(`/law_enforce?page=${1}`);
      lawEnforce(1, s);
    }
  };

  const handleKeySearch = (e) => {
    if (e.key === "Enter") {
      setPage(1);
      navigate(`/law_enforce?page=${1}`);
      lawEnforce(1, search.trimEnd());
    }
  };

  const viewAttachment = (file) => {
    var file_type = file.split(".").pop().split("?")?.[0];
    console.log("file_type", file_type);
    if (file_type === "doc" || file_type === "docx" || file_type === "pdf") {
      window.open(file, "_blank").focus();
    } else {
      setImageDoc(file);
      setImageModal(true);
    }
  };
  const downloadImage = (file) => {
    setLoading(true);
    fetch(file)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = file.split("/").pop();
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = fileName;

        // Programmatically trigger a click on the anchor element
        downloadLink.click();

        // Clean up by revoking the object URL
        URL.revokeObjectURL(url);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setLoading(false);
      });
  };

  const handleImageClick = async (rowData) => {
    setLoading(true);
    try {
      const response = await updateLawEnforcementStatus(rowData.id, true);
      setLoading(false);
      if (response.status === 200) {
        lawEnforce(page, search);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0">Law Enforcement</h2>
                </div>
              </div>
              <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                  onKeyDown={(e) => handleKeySearch(e)}
                />
              </div>
              <div className="table_box text-center">
                {data.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th className="date-box">CREATED</th>
                        <th>ORGANIZATION</th>
                        <th>PHONE NUMBER</th>
                        <th className="email-box">EMAIL</th>
                        <th>REQUESTING PARTY</th>
                        <th>MESSAGE</th>
                        <th>STATUS</th>
                        <th>ATTACHMENT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr>
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td className="date-box">
                            {moment(d.createdAt).format("YYYY-MM-DD")}
                          </td>
                          <td>{d.organization ? d.organization : "-"}</td>
                          <td>{d.phone ? d.phone : "-"}</td>
                          <td className="email-box">
                            {d.email ? d.email : "-"}
                          </td>
                          <td>{d.title ? d.title : "-"}</td>
                          {d.message?.length <= 15 ? (
                            <td>{d.message ? d.message : "-"}</td>
                          ) : (
                            <td>
                              {(d?.message).substring(0, 15)}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="btn-view-2">
                                    See Complete Message
                                  </Tooltip>
                                }
                              >
                                <a
                                  className="contact-message"
                                  onClick={() => viewMessage(d)}
                                >
                                  ...view more
                                </a>
                              </OverlayTrigger>
                            </td>
                          )}
                          <td>
                            {/* Clickable image */}
                            {d.status ? (
                              <img
                                src={require("../../../assets/images/Group.png")}
                                width={15}
                                alt="Group"
                              />
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleImageClick(d)}
                              >
                                <img
                                  src={require("../../../assets/images/Vector.png")}
                                  width={15}
                                  alt="Vector"
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="btn-view-1">
                                  View Attachment
                                </Tooltip>
                              }
                            >
                              <a
                                onClick={() => viewAttachment(d.attachment)}
                                className="btn border-0 bg-transparent"
                              >
                                <img
                                  src={
                                    require("../../../assets/images/eye.svg")
                                      .default
                                  }
                                  alt=""
                                  width="25px"
                                />
                              </a>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {count > 10 && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  prevPageText="Previous"
                  nextPageText="Next"
                  onChange={(event) => {
                    setPage(event);
                    navigate(`/law_enforce?page=${event}`);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={imageModal}
        onHide={() => {
          setImageModal(false);
          setImageDoc("");
        }}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            Attachment Image
          </Modal.Title>
          <OverlayTrigger
            overlay={<Tooltip id="btn-view-2">Download Image</Tooltip>}
          >
            <div className="text-right" onClick={() => downloadImage(imageDoc)}>
              <i className="file-down fa fa-download" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="imgdiv1">
            <img src={imageDoc} alt="attachment" className="imgsize1" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-black"
            variant="unset"
            onClick={() => {
              setImageModal(false);
              setImageDoc("");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setMessage({ sub: "", msg: "" });
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Subject:</h5>
          <p>{message.sub}</p>
          <h5>Message:</h5>
          <p>{message.msg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-black"
            variant="unset"
            onClick={() => {
              setShow(false);
              setMessage({ sub: "", msg: "" });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};
