import { axiosInstance } from "../config/api";

export const sendBroadcast = (data) => {
  const url = "/broadcast";
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const getAllBroadcastList = (page) => {
  const url = `admin/broadcast-list?page=${page - 1 ?? 0}&limit=10`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const getUserResponse = (id) => {
  const url = `admin/broadcast-response/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const getUserResponseList = (id, responseId, page) => {
  const url = `admin/broadcast-response/user-details/${id}?response=${responseId}&page=${
    page - 1 ?? 0
  }&limit=10`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const getUserIDResponseList = (id, page) => {
  const url = `admin/broadcast-response/user-details/${id}?page=${
    page - 1 ?? 0
  }&limit=10`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
