/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../layout/loader";
import {
  getKarmaBitchList,
  // publishKarmaScoreList,
  sendCongrats,
} from "../../../services/karma.services";
import { commonLogoutFunc } from "../../../utils/share";

const Karma = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [receiver, setReceiver] = useState("");
  const congrats = require("../../../assets/CongratsMessage.json");
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (query !== "" && query.get("page") !== "") {
      var current_page = query.get("page");
      topFemales(current_page, date);
      setPage(parseInt(current_page));
    } else {
      navigate(`/karma?page=${1}&limit=10`);
    }
  }, [query]);

  const topFemales = async (page, date) => {
    toast.dismiss();
    setLoading(true);
    try {
      const response = await getKarmaBitchList(
        date.getFullYear(),
        date.getMonth() + 1,
        page,
        10
      );
      setLoading(false);
      setData(response?.data?.data?.records ?? []);
      setCount(response?.data?.data?.totalCount ?? 0);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const sendMessage = (i, id) => {
    setMessage(congrats[i]);
    setReceiver(parseInt(id));
    setShow(true);
  };

  const sendCongratulations = async () => {
    toast.dismiss();
    if (message === "") {
      toast.error("Please enter a message");
      return false;
    }
    setLoading(true);
    // Simulate API call to send message

    try {
      console.log("receiver", receiver, "message", message);
      const response = await sendCongrats(receiver, { message: message });
      console.log("response", response);
      setLoading(false);
      if (response.status === 200) {
        setLoading(false);
        toast.success("Congratulation message send successfully");
        setShow(false);
      } else if (response.status === 401) {
        sessionStorage.clear();
        commonLogoutFunc();
        toast.error("Your session has been expired, Please login again!");
        return;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  // const publishResult = async () => {
  //   toast.dismiss();
  //   setLoading(true);
  //   // Simulate API call to publish result
  //   try {
  //     const response = await publishKarmaScoreList();
  //     setLoading(false);
  //     if (response.status === 200) {
  //       setLoading(false);
  //       toast.success("Result published successfully.");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error);
  //   }
  // };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0">Karma</h2>
                </div>
                <div className="text-right position-relative">
                  <DatePicker
                    selected={date}
                    placeholderText="Select month and year"
                    onChange={(date) => {
                      setDate(date);
                      navigate(`/karma?page=${1}`);
                      topFemales(1, date);
                    }}
                    className="pick-date"
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    showMonthYearPicker
                  />
                  <i className="fa fa-calendar clndr_icon"></i>
                </div>
              </div>
              {/* <div className="text-right mb-3">
                {data.length > 0 && (
                  <Button
                    className="btn-orange"
                    variant="unset"
                    onClick={() => publishResult()}
                  >
                    Publish Results
                  </Button>
                )}
              </div> */}
              <div className="table_box text-center">
                {data.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USER</th>
                        <th>POINTS</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="btn-view-1">
                                  View User Details
                                </Tooltip>
                              }
                            >
                              <h6
                                className="contact-name"
                                onClick={() =>
                                  navigate(`/user_details/${d.id}`)
                                }
                              >
                                {d.userName}
                              </h6>
                            </OverlayTrigger>
                          </td>
                          <td>{d.score}</td>
                          <td>
                            {(index === 0 || index === 1 || index === 2) &&
                            page === 1 ? (
                              <Button
                                className="btn-orange"
                                variant="unset"
                                onClick={() => sendMessage(index, d.id)}
                              >
                                Send Congratulations
                              </Button>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      // <img src={require('../assets/images/no-data.svg').default} alt="No Data" height="250" />
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {count > 10 && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  prevPageText="Previous"
                  nextPageText="Next"
                  onChange={(event) => {
                    setPage(event);
                    navigate(`/karma?page=${event}`);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setMessage("");
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            View or Edit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={12}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-orange"
            variant="unset"
            onClick={() => sendCongratulations()}
          >
            Send Message
          </Button>
          <Button
            className="btn-black"
            variant="unset"
            onClick={() => {
              setShow(false);
              setMessage("");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Karma;
