import { axiosInstance } from "../config/api";

export const getAllSearch = (page, limit, search) => {
  let url = "/dashboard/search?";
  url += `page=${page - 1 >= 0 ? page - 1 : 0}&limit=${
    limit || 10
  }&search=${search}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
