import { axiosInstance } from "../config/api";

export const getUsersList = (status, page, limit, search, plan) => {
  let url = `/user-list?`;
  url += `status=${status}&limit=${limit ?? 10}&page=${
    page - 1 ?? 0
  }&plan=${plan}&`;
  if (search) url += `search=${search}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const getUserDetails = (id) => {
  let url = `/user/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const activateOrDeactivateUser = (id, data) => {
  let url = `/user/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const getUserPlayDateHistory = (id) => {
  let url = `/user/play-date-history/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const verifyUser = async (id, data) => {
  const url = `/user-verification/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const healthCheck = () => {
  let url = `/health`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const GetUserCount = () => {
  let url = `/admin/user-count`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const DeleteUser = (id) => {
  let url = `/admindelete-user/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const sendEmail = async (status, data) => {
  const url = `admin/send-mail-users?status=${status}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};

export const EditUserName = async (id, data) => {
  const url = `admin/user-name/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data)
      .then((response) => resolve(response?.data))
      .catch((err) => reject(err));
  });
};
