import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getDeleteUserReasons } from "../../../services/deleteUsers.services";
import Loader from "../../../layout/loader";

const DeletedUsers = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const getDeleteReasonData = async () => {
    setLoading(true);
    try {
      const { data: { data = [] } = {} } = await getDeleteUserReasons();
      setLoading(false);
      setData(data);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || error);
    }
  };

  useEffect(() => {
    getDeleteReasonData();
  }, []);

  const staticData = [
    { reason: "UNLCKED FOUND MY MATCH", nav: "/found_my_match" },
    { reason: "FOUND MY PERSON ELSEWHERE", nav: "/found_my_person_elsewhere" },
    { reason: "PEOPLE STILL PLAY GAMES", nav: "/people_still_play_games" },
    { reason: "NEED TO BE ALONE FOR A BIT", nav: "/need_to_be_alone" },
    { reason: "DON'T LIKE THIS APP", nav: "/dont_like_app" },
    { reason: "DELETED BY ADMIN", nav: "/deleted_by_admin" },
  ];

  const mergedData = staticData.map((item, index) => {
    const apiItem = data[index + 1] || {};
    return {
      ...item,
      ...apiItem,
    };
  });

  const filteredData = mergedData.filter((item) =>
    item.reason.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0">{`Deleted (${
                    data?.totalDeletedUsers ?? 0
                  })`}</h2>
                </div>
              </div>
              <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div>Reasons chosen by the users while deleting the account</div>
              <div
                className="table_box text-center"
                style={{ marginTop: "20px" }}
              >
                {filteredData.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>REASON</th>
                        <th>NO. OF USERS</th>
                        <th>REASON %</th>
                        <th>USER DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.length > 0
                        ? filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.reason}</td>
                              <td>{item?.users}</td>
                              <td>{Math.round(item?.percentage)}%</td>
                              <td>
                                <Button
                                  onClick={() => navigate(item?.nav)}
                                  className="btn border-0 bg-transparent"
                                  variant="unset"
                                >
                                  <img
                                    src={
                                      require("../../../assets/images/eye.svg")
                                        .default
                                    }
                                    alt=""
                                    width="25px"
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DeletedUsers;
