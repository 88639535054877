import React from "react";
import { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import logo from '../../../assets/images/Logo.png';
import Loader from '../../../layout/loader';
import { pushNotification } from "../../../utils/notification";
import { NOTIFICATION } from "../../../constants";
import './contact.css';
import axios from "axios";
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').min(2),
    email: Yup.string().email("Please enter valid email").required('email is required'),
    message: Yup.string().required('Message is required').min(10),

});


const Contact = () => {
    const [isLoading, setLoading] = useState(false);


    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const response = await axios.post("https://app.unlckedapp.com/rest/contact-us", values);
            pushNotification(response?.data?.message, NOTIFICATION.SUCCESS);
        } catch (e) {
            pushNotification(e?.response?.data?.message, NOTIFICATION.ERROR);

        } finally {
            setLoading(false);
            formik.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    return (

        <div className="reset-password-page">
            <div className="header">
                <img src="" alt="" />
            </div>
            <Loader promiseInProgress={isLoading} />
            <section className='reset-password' id='login'>
                {/* <img src={logo} alt="logo" className="reset-password-logo" /> */}
                <div>
                    <h1>
                        Contact Us
                    </h1>
                    <p style={{ fontSize: "20px" }}>
                        Reach out and get in contact with us.
                    </p>
                </div>
                <div className='form'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                id="name"
                                placeholder="Name"
                                required
                                {...formik.getFieldProps('name')}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="error-message">{formik.errors.name}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                id="email"
                                placeholder="Email"
                                required
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error-message">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <textarea
                                id="message"
                                placeholder="Message"
                                required
                                {...formik.getFieldProps('message')}
                            />
                            {formik.touched.message && formik.errors.message ? (
                                <div className="error-message">{formik.errors.message}</div>
                            ) : null}
                        </div>

                        <button className="btn-reset-password" type="submit">Submit</button>
                    </form>
                </div>
                <div><br />
                    <p>Reach out to <a href="mailto:hello.unlcked@gmail.com">hello.unlcked@gmail.com</a></p>
                </div>
            </section>
            <div className="footer"></div>
        </div>
    );

}

export default Contact;
