export const commonLogoutFunc = async () => {
  resetLocalStorage();
  window.location.replace('/');
};
export const resetLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const isLoggedIn = () => {
  let redux = JSON.parse(localStorage.getItem('redux'));
  if (redux?.login?.token) {
    return true;
  }
  return false;
};

