import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../../assets/images/closeIcon.svg";
import "./customModal.css";

function CustomModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`customModal ${props.size === "small" ? "reduceWidth" : ""} ${
        props?.className
      }`}
      backdrop={props.onHide ? "static" : true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.heading || ""}
        </Modal.Title>
        {props.hideCross ? (
          ""
        ) : (
          <Button variant="link" onClick={props.onHide}>
            <img src={closeIcon} alt="closeIcon" />
          </Button>
        )}
      </Modal.Header>
      {props.underline ? <div className="line"></div> : ""}
      <Modal.Body>{props?.body || ""}</Modal.Body>
      {props.footer ? <Modal.Footer>{props?.footer || ""}</Modal.Footer> : ""}
    </Modal>
  );
}

export default CustomModal;
