/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Dropdown, Row, Toast } from "react-bootstrap";
import pro from "../assets/images/pro.png";
import signout from "../assets/images/signout.png";
import logo from "../assets/images/Logo.png";
import hamBurgerIcon from "../assets/images/hamburger.svg";
import { commonLogoutFunc, isLoggedIn } from "../utils/share";
import dashboardSearch from '../assets/images/deshboardSearch.svg';
const Header = () => {
  const navigate = useNavigate();
  const login = useSelector((state) => state?.login);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleSidebar = () => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  };

   useEffect(() => {
      if(location?.pathname !== '/search')
        {setSearch('')}
   }, [location?.pathname])

  useEffect(() => {
    const isAuthenticated = isLoggedIn();
    if (!isAuthenticated) {
      sessionStorage.clear();
      navigate("/");
      return false;
    }
  }, []);

  const Logout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF6700",
      cancelButtonColor: "#2B2B2B",
      confirmButtonText: "Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        commonLogoutFunc();
      }
    });
  };

  const handleKeySearch = (e) => {
    if (
      e.key === "Enter" ||
      e.keyCode === 13 ||
      e.key === "Go" ||
      e.key === "Search" ||
      e.key === "Next"
    ) {
      const searchParams = new URLSearchParams({ search: search });
      navigate(`/search?${searchParams.toString()}`);
    }
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  return (
    <header className="headerWrap">
      <Container fluid>
        <Row>
          <Col sm={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="hamburger-menu">
                <img
                  src={hamBurgerIcon}
                  alt=""
                  onClick={toggleSidebar}
                  className="hamburger-icon"
                />
              </div>
              <div className="main_logo">
                <img
                  src={logo}
                  alt=""
                  onClick={() => navigate("/")}
                  className="header-logo-img"
                />
              </div>
             {login?.user?.staticRole === 1 && <div
                className="mr-auto ml-3"
                style={{ width: "19rem" }}
              >
                <div className="position-relative form-group has-search search-clear mt-2 mb-0">
                  <img className="position-absolute searchIconWrap" src={dashboardSearch} alt='searchIcon' />
                  <input
                    style={{ maxWidth: "300px" }}
                    type="text"
                    className="form-control w-100 inputWrap"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    onKeyDown={(e) => handleKeySearch(e)}
                  />
                </div>
              </div>}
              <div>
                <ul className="m-0 p-0">
                  <li className="profile-opt">
                    <Dropdown className="profile_dropdown">
                      <Dropdown.Toggle
                        variant="unset"
                        className="border-0"
                        id="dropdown-basic"
                      >
                        <i
                          className="profile-drop fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <p>Logged in</p>
                        <h6 className="mb-3">{login?.user?.email}</h6>
                        <Dropdown.Item
                          onClick={() => navigate("/profile")}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={pro}
                            alt=""
                            style={{ marginRight: "8px", maxHeight: "20px" }}
                          />
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => Logout()}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={signout}
                            alt=""
                            style={{ marginRight: "8px", maxHeight: "20px" }}
                          />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
              <Toast
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  minWidth: "330px",
                  zIndex: 9999,
                }}
                autohide
                delay={8000}
                className="m-3 shadow"
                show=""
                onClose=""
              >
                <Toast.Header className="bg-dark text-white pt-2">
                  <img src="" height={16} className="rounded mr-2" alt="" />
                  <strong className="mr-auto">Heading</strong>
                </Toast.Header>
                <Toast.Body className="py-2">
                  <div className="d-flex align-items-baseline">
                    <p>Message.....</p>
                  </div>
                </Toast.Body>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
