/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../layout/loader";
import {
  getAllPressCollab,
  updatePressCollabStatus,
} from "../../../services/contact.services";
// import { commonLogoutFunc } from "../../../utils/share";

const ContactUs = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({ sub: "", msg: "" });
  const [query] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (query !== "" && query.get("page") !== "") {
      var current_page = query.get("page");
      contact_us(current_page, search);
      setPage(parseInt(current_page));
    } else {
      navigate(`/contact?page=${1}`);
    }
  }, [query]);

  const contact_us = async (page, search) => {
    toast.dismiss();
    setLoading(true);
    try {
      const response = await getAllPressCollab(page, 10, search);
      setLoading(false);
      setData(response?.data?.data?.records ?? []);
      setCount(response?.data?.data?.totalCount ?? 0);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleSearch = (s) => {
    setSearch(s.trimStart());
    if (s === "") {
      navigate(`/contact?page=${1}`);
      contact_us(1, s);
    }
  };

  const handleKeySearch = (e) => {
    if (e.key === "Enter") {
      setPage(1);
      navigate(`/contact?page=${1}`);
      contact_us(1, search.trimEnd());
    }
  };

  const viewMessage = (contact) => {
    setShow(true);
    setMessage({ sub: contact.subject, msg: contact.message });
  };
  const handleImageClick = async (rowData) => {
    setLoading(true);
    try {
      const response = await updatePressCollabStatus(rowData.id, true);
      setLoading(false);
      if (response.status === 200) {
        contact_us(page, search);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0">Press or Collaboration</h2>
                </div>
              </div>
              <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                  onKeyDown={(e) => handleKeySearch(e)}
                />
              </div>
              <div className="table_box text-center">
                {data.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>DATE</th>
                        <th>MESSAGE</th>
                        <th>NAME</th>
                        <th>PHONE NUMBER</th>
                        <th>EMAIL</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr>
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td>{moment(d.date).format("YYYY-MM-DD")}</td>
                          {d.message.length <= 15 ? (
                            <td>{d.message}</td>
                          ) : (
                            <td>
                              {d.message.substring(0, 15)}...
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="btn-view-2">
                                    See Complete Message
                                  </Tooltip>
                                }
                              >
                                <a
                                  className="contact-message"
                                  onClick={() => viewMessage(d)}
                                >
                                  view more
                                </a>
                              </OverlayTrigger>
                            </td>
                          )}
                          <td>{d.fullName}</td>
                          <td>{d.phoneNumber ? d.phoneNumber : "N/A"}</td>
                          <td>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="btn-view-1">
                                  View User Details
                                </Tooltip>
                              }
                            >
                              <h6
                                className="contact-name"
                                onClick={() =>
                                  navigate(`/user_details/${d.userId}`)
                                }
                              >
                                {d.email}
                              </h6>
                            </OverlayTrigger>
                          </td>
                          <td>
                            {d.status ? (
                              <img
                                src={require("../../../assets/images/Group.png")}
                                width={15}
                                alt="Group"
                              />
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleImageClick(d)}
                              >
                                <img
                                  src={require("../../../assets/images/Vector.png")}
                                  width={15}
                                  alt="Vector"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      // <img src={require('../assets/images/no-data.svg').default} alt="No Data" height="250" />
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {count > 10 && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  prevPageText="Previous"
                  nextPageText="Next"
                  onChange={(event) => {
                    setPage(event);
                    navigate(`/contact?page=${event}`);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setMessage({ sub: "", msg: "" });
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Subject:</h5>
          <p>{message.sub}</p>
          <h5>Message:</h5>
          <p>{message.msg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-black"
            variant="unset"
            onClick={() => {
              setShow(false);
              setMessage({ sub: "", msg: "" });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default ContactUs;
