/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../../../layout/loader";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getUserIDResponseList,
  getUserResponseList,
} from "../../../services/broadcast";
import CustomModal from "../../common/CustomModal/CustomModal";
import Pagination from "react-js-pagination";

const CommentDetails = () => {
  const { id, responseId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [showAdditionInput, setShowAdditionInput] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedComment, setSelectedComment] = useState();
  const [filteredDataListing, setFilteredDataListing] = useState([]);
  const [additionDescriptionResponsen, setAdditionDescriptionResponse] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdditionalMessage, setIsAdditionalMessage] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { records, totalCount, isAdditionalDescriptionAdd } = {},
        } = {},
      } = await getUserResponseList(id, responseId, page);
      setData(records);
      setIsAdditionalMessage(isAdditionalDescriptionAdd);
      setCount(totalCount);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const fetchData2 = async () => {
    setLoading(true);
    try {
      const { data: { data: { records, totalCount } = {} } = {} } =
        await getUserIDResponseList(id, page);
      setData(records);
      setCount(totalCount);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredList = data.filter((item) => {
        const text = item.user.userName;
        return text.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredDataListing(filteredList);
    } else {
      setFilteredDataListing(data);
    }
  }, [searchTerm, data]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const DirectResponse = id === responseId;
    setAdditionDescriptionResponse(DirectResponse);
    if (DirectResponse) {
      fetchData2();
    } else {
      fetchData();
    }
  }, [page]);

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return (
      <>
        {description.slice(0, maxLength)}
        <span>... </span>
        <span style={{ color: "#FF6700", cursor: "pointer" }}>view more</span>
      </>
    );
  };

  const handleSelectedComponent = (comment) => {
    setSelectedComment(comment);
    setShowAdditionInput(true);
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div>
                <div className="back-heading mb-3">
                  <div
                    onClick={() =>
                      navigate(
                        !additionDescriptionResponsen
                          ? `/users_response/${id}`
                          : "/app_communication"
                      )
                    }
                  >
                    <img
                      src={
                        require("../../../assets/images/leftArrow.svg").default
                      }
                      alt=""
                      width="25px"
                    />
                  </div>
                  <div className="trans_head btn_title_row">
                    <h2 className="mb-0">User's Details</h2>
                  </div>
                </div>
                <div className="form-group has-search search-clear">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="table_box text-center">
                {filteredDataListing?.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USERNAME</th>
                        <th>DATE OF INPUT</th>
                        {isAdditionalMessage ? <th>ADDITIONAL INPUT</th> : ""}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataListing?.map((d, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            if (d.additionalResponse !== "") {
                              handleSelectedComponent(d.additionalResponse);
                            }
                          }}
                          style={{
                            cursor:
                              d?.additionalResponse === "" ? "" : "pointer",
                          }}
                        >
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td>{d?.user?.userName}</td>
                          <td>{formatDate(d?.createdAt)}</td>
                          {isAdditionalMessage ? (
                            <td>
                              {d.additionalResponse !== ""
                                ? truncateDescription(d?.additionalResponse, 40)
                                : "-"}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(event) => {
                        setPage(event);
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <CustomModal
              show={showAdditionInput}
              heading="Additional Input"
              onHide={() => {
                setShowAdditionInput(false);
              }}
              underline
              body={<div>{selectedComment}</div>}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CommentDetails;
