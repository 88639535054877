import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import logo from "../../../assets/images/Logo.png";
import Loader from "../../../layout/loader";
import { fileUpload } from "../../../services/file-upload.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import { commonLogoutFunc } from "../../../utils/share";
import { addAdmin } from "../../../services/admin.services";
import { useNavigate } from "react-router";

const AdminForm = () => {
  const [img, setImg] = useState({ file: "", url: "" });
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleImage = async (e) => {
    setLoading(true);
    const file = e?.target?.files?.[0];
    var regex = new RegExp("(.*?).(jpg|jpeg|png|PNG)$");
    if (!regex.test(file?.name)) {
      Swal.fire("", "Please select valid image file.", "warning");
      return;
    }
    const imgResp = await fileUpload(file, file.name);
    console.log("imgResp", imgResp);
    setLoading(false);
    setImg({ file: file, url: imgResp.getUrl, key: imgResp.key });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name."),
      lastName: Yup.string().required("Please enter last name."),
      userName: Yup.string().required("Please enter user name."),
      email: Yup.string()
        .email("Please enter a valid email.")
        .required("Please enter email."),
      password: Yup.string()
        .required("Please enter a password.")
        .min(8, "Password must be at least 8 characters.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
        ),
    }),
    onSubmit: (values) => {
      handleAddAdmin(values);
    },
  });

  const handleAddAdmin = async (values) => {
    setLoading(true);
    values.profilePhoto = img.key;
    console.log("values", values);

    try {
      setLoading(false);
      const response = await addAdmin(values);
      console.log("response", response);
      if (!response.data.error) {
        toast.success("Profile Updated Successfully.");
        navigate(`/admin?page=${1}`);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        sessionStorage.clear();
        commonLogoutFunc();
        toast.error("Your session has been expired, Please login again!");
        return;
      }
    }
  };

  return (
    <section className="botmanagement_section edit_bot_details profile_section">
      <Loader promiseInProgress={isLoading} />

      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head pb-1">
                <div>
                  <h2 className="mb-1">Create Admin</h2>
                </div>
              </div>
              <div className="details_box mt-2">
                {!isLoading ? (
                  <Row>
                    <Col md={4} className="text-center profilebtn">
                      <div className="edit_bot_img m-auto">
                        <img
                          style={{ borderRadius: "50%" }}
                          height="250"
                          width="250"
                          src={img.url ? img.url : logo}
                          alt=""
                        />
                      </div>
                      <div className="profile-btn">
                        <Button
                          className="btn updatedbtn border-0"
                          variant="unset"
                        >
                          {"Upload"} Profile Picture
                        </Button>
                        <input
                          className="change-profile-input c-pointer"
                          onChange={(e) => handleImage(e)}
                          type="file"
                          accept="image/jpg, image/jpeg, image/png"
                        />
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <Form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 mt-2"
                                  controlId="formBasicFirst"
                                >
                                  <Form.Label>FIRST NAME:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Admin First Name"
                                    {...formik.getFieldProps("firstName")}
                                    className="input101"
                                  />
                                  {formik.touched.firstName &&
                                    formik.errors.firstName && (
                                      <div className="error">
                                        {formik.errors.firstName}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 mt-2"
                                  controlId="formBasicLast"
                                >
                                  <Form.Label>LAST NAME:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Admin Last Name"
                                    className="input101"
                                    {...formik.getFieldProps("lastName")}
                                  />
                                  {formik.touched.lastName &&
                                    formik.errors.lastName && (
                                      <div className="error">
                                        {formik.errors.lastName}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 mt-2"
                                  controlId="formBasicLast"
                                >
                                  <Form.Label>USERNAME:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Admin Username"
                                    {...formik.getFieldProps("userName")}
                                    className="input101"
                                  />
                                  {formik.touched.userName &&
                                    formik.errors.userName && (
                                      <div className="error">
                                        {formik.errors.userName}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 mt-2"
                                  controlId="formBasicLast"
                                >
                                  <Form.Label>PASSWORD:</Form.Label>
                                  <Form.Control
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="Enter Admin Password"
                                    {...formik.getFieldProps("password")}
                                    className="input101"
                                  />
                                  {formik.touched.password &&
                                    formik.errors.password && (
                                      <div className="error">
                                        {formik.errors.password}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>

                              <Col md={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>EMAIL:</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Admin Email"
                                    {...formik.getFieldProps("email")}
                                    className={"input101"}
                                  />
                                  {formik.touched.email &&
                                    formik.errors.email && (
                                      <div className="error">
                                        {formik.errors.email}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Button
                                  className="btn-orange my-3"
                                  variant="unset"
                                  type="submit"
                                >
                                  Create Admin
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                  </Row>
                ) : (
                  <div className="text-center py-3">
                    <h6>Please wait...</h6>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AdminForm;
