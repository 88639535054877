import { axiosInstance } from "../config/api";

export const getDeleteUserReasons = () => {
  let url = "admin/delete-reason";
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getDeletedUsers = (reasonId, page) => {
  let url = `user-list/deleted?reasonId=${reasonId}&limit=10&page=${
    page - 1 ?? 0
  }&plan=&`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
