import './App.css';
import './assets/css/style.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SecureRoutes from './routes/Routing';
// import Layout from './layout/Layout';

function App() {

  // const selector = useSelector((state) => state)
  return (
    <div className="App">

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
      />
      <SecureRoutes />

    </div >
  );
}

export default App;
