import { Col, Container, Row } from "react-bootstrap";

const Footer = (props) => {

    var year = new Date().getFullYear();

    return (
        <footer className="py-4">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="text-left">
                            <p className="footer_text text-left mb-0">&copy; {year} UNLCKED | ALL RIGHTS RESERVED.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );

}

export default Footer;
