import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./customTabs.css";

function CustomTabs({ tabsList = [], tabSelected = 0, setTabs = () => null }) {
  return (
    <Tabs
      activeKey={tabSelected}
      defaultActiveKey={0}
      onSelect={(e) => setTabs(e)}
    >
      {tabsList.map((item, index) => {
        return <Tab key={item.id + index} eventKey={item.id} title={item?.title} />;
      })}
    </Tabs>
  );
}

export default CustomTabs;
