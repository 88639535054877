/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import Loader from "../../../layout/loader";
import CustomModal from "../../common/CustomModal/CustomModal";
import {
  getAllBroadcastList,
  sendBroadcast,
} from "../../../services/broadcast";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import crossIcon from "../../../assets/images/cross.svg";
import circleIcon from "../../../assets/images/circleIcon.svg";
import SwitchButton from "../../common/SwitchButton/SwitchButton";
import CustomTabs from "../../common/Tabs/CustomTabs";

const tabsList = [
  {
    id: 0,
    title: "Send a message",
  },
  {
    id: 1,
    title: "Ask a question",
  },
];

const InAppCommunication = () => {
  const [filteredDataListing, setFilteredDataListing] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [broadcastCount, setBroadcastCount] = useState(0);

  const [tabSelected, setTabSelected] = useState(0);

  const [inputErrorMessage, setInputErrorMessage] = useState("");

  const handleDispatchApi = async (payload) => {
    try {
      setLoading(false);
      const response = await sendBroadcast(payload);
      if (!response.data.error) {
        toast.success("Broadcast sent successfully.");
        setModalShow(false);
        navigate(`/app_communication?page=${1}`);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.message);
    }
    setShowConfirmation(false);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    await handleDispatchApi(showConfirmation);
    formikSendMessage.resetForm();
    formikSendInput.resetForm();
    fetchData();
  };
  const handleMessageSubmit = async (values) => {
    const payload = {
      broadCastType: 1,
      message: values?.message,
    };
    setShowConfirmation(payload);
    setModalShow(false);
  };

  const handleSendMessageSubmit = async (values) => {
    let optionsObject = {};
    values?.options.forEach((value, index) => {
      if (value) {
        optionsObject[index + 1] = value;
      }
    });
    if (
      Object.values(optionsObject).length === 0 &&
      !values?.additionalDescription
    ) {
      setInputErrorMessage("Please select an input type for the user");
    } else if (Object.values(optionsObject).length === 1) {
      setInputErrorMessage("Please enter minimum two options for the user");
    } else {
      const payload = {
        broadCastType: 2,
        question: values?.question,
        options: optionsObject,
        isAdditionalDescriptionAdd: values?.additionalDescription,
      };
      setShowConfirmation(payload);
      setModalShow(false);
    }
  };

  const formikSendMessage = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required("This field is mandatory")
        .max(500, "Message cannot exceed 500 characters"),
    }),
    onSubmit: (values) => {
      handleMessageSubmit(values);
    },
  });

  const formikSendInput = useFormik({
    initialValues: {
      question: "",
      options: [""],
      additionalDescription: false,
    },
    validationSchema: Yup.object({
      question: Yup.string()
        .required("This field is mandatory")
        .max(500, "Question cannot exceed 500 characters"),
    }),
    onSubmit: (values) => {
      handleSendMessageSubmit(values);
    },
  });

  useEffect(() => {
    formikSendMessage.resetForm();
    formikSendInput.resetForm();
  }, [tabSelected]);

  const renderSendMessageContent = () => {
    return (
      <Form>
        <Form.Group className="mt-4" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={5}
            value={formikSendMessage?.values?.message}
            onChange={(e) =>
              formikSendMessage?.setFieldValue("message", e.target.value)
            }
            className="no-resize pb-3"
            placeholder="Enter your message here..."
            maxLength={500}
          />
          <small className="float-right mt-1">
            {formikSendMessage?.values?.message?.length || 0}/500
          </small>
        </Form.Group>
        {formikSendMessage.touched.message &&
          formikSendMessage.errors.message && (
            <div className="error">{formikSendMessage.errors.message}</div>
          )}
      </Form>
    );
  };

  const renderSeekInputContent = () => {
    return (
      <Form>
        <Form.Group className="mt-4" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="font-weight-500">Question</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formikSendInput?.values?.question}
            onChange={(e) =>
              formikSendInput?.setFieldValue("question", e.target.value)
            }
            className="no-resize pb-3"
            placeholder="Enter your question here..."
            maxLength={500}
          />
          <small className="float-right mt-1">
            {formikSendInput?.values?.question?.length || 0}/500
          </small>
        </Form.Group>
        {formikSendInput.touched.question &&
          formikSendInput.errors.question && (
            <div className="error">{formikSendInput.errors.question}</div>
          )}
        <div>
          <Form.Group className="mt-3">
            <Form.Label className="font-weight-500">Options</Form.Label>
            {formikSendInput?.values?.options.map((item, index) => {
              return (
                <Form.Group
                  className="mb-3 position-relative"
                  key={"option" + index}
                >
                  <img
                    src={circleIcon}
                    alt="cross"
                    className="circleIconField"
                  />
                  <Form.Control
                    type="text"
                    className="padding-left-30"
                    placeholder="Enter Option Text"
                    name={`options[${index}]`}
                    value={item || ""}
                    maxLength={50}
                    onChange={(e) => {
                      formikSendInput?.setFieldValue(
                        `options[${index}]`,
                        e.target.value
                      );
                      setInputErrorMessage("");
                    }}
                  />
                  {index > 0 ? (
                    <img
                      src={crossIcon}
                      alt="cross"
                      className="crossIconField"
                      onClick={() =>
                        formikSendInput?.setFieldValue("options", [
                          ...formikSendInput?.values?.options.slice(0, index),
                          ...formikSendInput?.values?.options.slice(index + 1),
                        ])
                      }
                    />
                  ) : (
                    ""
                  )}
                  {formikSendInput?.touched?.options &&
                    formikSendInput?.touched?.options[index] &&
                    formikSendInput?.errors?.options &&
                    formikSendInput?.errors?.options[index] && (
                      <div className="error">
                        {formikSendInput?.errors?.options[index]}
                      </div>
                    )}
                </Form.Group>
              );
            })}
          </Form.Group>
          <Button
            className="bg-transparent error float-right"
            variant="unset"
            onClick={() => {
              formikSendInput.setFieldValue("options", [
                ...formikSendInput?.values?.options,
                "",
              ]);
            }}
            disabled={formikSendInput?.values?.options.length === 4}
          >
            <i className="fa fa-plus error"></i> Add option
          </Button>
        </div>
        <SwitchButton
          label="Do you want another additional descriptive input from user?"
          value={formikSendInput?.values?.additionalDescription}
          handleChange={(e) => {
            formikSendInput.setFieldValue("additionalDescription", e);
            setInputErrorMessage("");
          }}
        />
        {inputErrorMessage ? (
          <div className="error">{inputErrorMessage}</div>
        ) : (
          ""
        )}
      </Form>
    );
  };

  const renderTabContent = () => {
    switch (tabSelected) {
      case "1":
        return renderSeekInputContent();
      default:
        return renderSendMessageContent();
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <CustomTabs
          tabsList={tabsList}
          tabSelected={tabSelected}
          setTabs={(e) => setTabSelected(e)}
        />
        {renderTabContent()}
      </>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { records, totalCount, broadcastCountOfThisMonth } = {},
        } = {},
      } = await getAllBroadcastList(page);
      setData(records);
      setCount(totalCount);
      setBroadcastCount(broadcastCountOfThisMonth);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const truncateDescription = (description, maxLength, id) => {
    if (description.length <= maxLength) {
      return description;
    }
    return (
      <>
        {description.slice(0, maxLength)}
        <span>... </span>
        <span style={{ color: "#FF6700", cursor: "pointer" }}>view more</span>
      </>
    );
  };
  const handleSelectedMessage = (message) => {
    setSelectedMessage(message);
    setShowMessageSent(true);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredList = data.filter((item) => {
        const text =
          item.message !== null
            ? item.message
            : item.question
            ? item.question
            : "-";
        return text.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredDataListing(filteredList);
    } else {
      setFilteredDataListing(data);
    }
  }, [searchTerm, data]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleAddBroadcast = () => {
    if (broadcastCount >= 10) {
      toast.error("Broadcast limit reached for this month");
    } else {
      setModalShow(true);
    }
  };

  return (
    <section className="botmanagement_section">
      <CustomModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        heading="New Broadcast"
        body={renderModalContent()}
        footer={
          <>
            <Button
              className="btn-orange bg-light text-dark border border-1 border-secondary"
              variant="unset"
              onClick={() => {
                setModalShow(false);
                formikSendMessage.resetForm();
                formikSendInput.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-orange"
              variant="unset"
              onClick={() =>
                tabSelected === "1"
                  ? formikSendInput.handleSubmit(formikSendInput.values)
                  : formikSendMessage.handleSubmit(formikSendMessage.values)
              }
            >
              Submit
            </Button>
          </>
        }
      />

      <CustomModal
        show={showConfirmation}
        hideCross
        size="small"
        onHide={() => setShowConfirmation(false)}
        body={
          <div className=" text-center">
            Are you sure to send this to all users now?
          </div>
        }
        footer={
          <div className="w-100 d-flex justify-content-between custom-column-gap">
            <Button
              className="btn-orange bg-light text-dark border border-1 border-secondary w-50"
              variant="unset"
              onClick={() => {
                setShowConfirmation(false);
                setModalShow(true);
              }}
            >
              No
            </Button>
            <Button
              className="btn-orange w-50"
              variant="unset"
              onClick={() => handleConfirmSubmit()}
            >
              Yes
            </Button>
          </div>
        }
      />
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="trans_head mb-3 btn_title_row">
                    <h2 className="mb-0">In-App Communication</h2>
                  </div>
                  <div className="form-group has-search search-clear">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    className="btn-orange"
                    variant="unset"
                    onClick={() => handleAddBroadcast()}
                  >
                    New Broadcast
                  </Button>
                </div>
              </div>
              <div className="table_box text-center">
                {filteredDataListing?.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>DATE</th>
                        <th>MESSAGE</th>
                        <th>RESPONSES</th>
                        <th>DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataListing.map((d, index) => (
                        <tr
                          key={index}
                          onClick={() =>
                            handleSelectedMessage(
                              d.message !== null
                                ? d.message
                                : d.question
                                ? d.question
                                : "-"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td>{formatDate(d?.createdAt)}</td>
                          <td>
                            {d.message !== null
                              ? truncateDescription(d.message, 40)
                              : d.question
                              ? truncateDescription(d.question, 40)
                              : "-"}
                          </td>
                          <td>{d.responseCount ? d.responseCount : "-"}</td>
                          <td>
                            {d.broadCastType !== 1 ? (
                              <Button
                                className="btn border-0 bg-transparent"
                                variant="unset"
                              >
                                <img
                                  src={
                                    require("../../../assets/images/eye.svg")
                                      .default
                                  }
                                  alt=""
                                  width="25px"
                                  onClick={() =>
                                    d?.options !== "{}"
                                      ? navigate(`/users_response/${d.id}`)
                                      : navigate(
                                          `/users_comment_details/${d.id}/${d.id}`
                                        )
                                  }
                                />
                              </Button>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(event) => {
                        setPage(event);
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <CustomModal
              show={showMessageSent}
              heading="Message"
              onHide={() => {
                setShowMessageSent(false);
              }}
              underline
              body={<div>{selectedMessage}</div>}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InAppCommunication;
