import { axiosInstance } from '../config/api';

export const getPromoWithParticipantsCount = (page, limit) => {
    let url = '/promo?';
    url += `page=${page - 1 ?? 0}&limit=${limit ?? 10}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};