/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { getUserResponse } from "../../../services/broadcast";
import Loader from "../../../layout/loader";

const UsersResponse = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDataListing, setFilteredDataListing] = useState();

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data: { data } = {} } = await getUserResponse(id);
      setData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredList = data.filter((item) => {
        const text = item?.option_value;
        return text.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredDataListing(filteredList);
    } else {
      setFilteredDataListing(data);
    }
  }, [searchTerm, data]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div>
                <div className="back-heading mb-3">
                  <div onClick={() => navigate("/app_communication")}>
                    <img
                      src={
                        require("../../../assets/images/leftArrow.svg").default
                      }
                      alt=""
                      width="25px"
                    />
                  </div>
                  <div className="trans_head btn_title_row">
                    <h2 className="mb-0">User's Response</h2>
                  </div>
                </div>
                <div className="form-group has-search search-clear">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="table_box text-center">
                {filteredDataListing?.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>OPTIONS</th>
                        <th>NO. OF RESPONSES</th>
                        <th> USER DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataListing?.map((d, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{d?.option_value}</td>
                          <td>{d?.user_count}</td>
                          <td>
                            {d.broadCastType !== 1 ? (
                              <Button
                                className="btn border-0 bg-transparent"
                                variant="unset"
                                onClick={() =>
                                  navigate(
                                    `/users_comment_details/${id}/${d?.response}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    require("../../../assets/images/eye.svg")
                                      .default
                                  }
                                  alt=""
                                  width="25px"
                                />
                              </Button>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UsersResponse;
