/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet, useNavigate } from "react-router";
import { isLoggedIn } from "../utils/share";

const Layout = () => {
    const navigate = useNavigate();
    const isAuthenticated = isLoggedIn();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/')
            return false
        }
    }, [])

    return (
        <>
            {isAuthenticated ?
                <>
                    <Header />
                    <div className="mian_content false">
                        <Sidebar />
                        <div className="content">
                            <div className="custom_height">
                                <Outlet />

                            </div>
                            <Footer />
                        </div>
                    </div>
                </>
                :
                <>
                    <Outlet />

                </>
            }
        </>
    );

}

export default Layout;
