import { toast } from 'react-toastify';
import { NOTIFICATION } from '../constants';

// const LAST_NOTIFICATION_KEY = 'last-notification';
// const LAST_NOTIFICATION_TIME_KEY = 'last-notification-time';

let lastNotification;
let lastNotificationTime;

export function pushNotification(msg, type) {
  if (msg === lastNotification && Date.now() - lastNotificationTime < 1000) {
    // if the same message was shown within last 1 seconds, do not show it again
    return;
  }
  switch (type) {
    case NOTIFICATION.INFO:
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        newestOnTop: true,
        theme: 'colored',
      });
      break;
    case NOTIFICATION.SUCCESS:
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        newestOnTop: true,
        theme: 'colored',
      });
      break;

    case NOTIFICATION.WARNING:
      toast.warn(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        newestOnTop: true,
        theme: 'colored',
      });
      break;
    case NOTIFICATION.ERROR:
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        newestOnTop: true,
        theme: 'colored',
      });
      break;
    default:
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        newestOnTop: true,
        theme: 'colored',
      });
      break;
  }

  // // update the last notification and time in local storage
  // localStorage.setItem(LAST_NOTIFICATION_KEY, msg);
  // localStorage.setItem(LAST_NOTIFICATION_TIME_KEY, Date.now());

  // update the last notification and time in memory
  lastNotification = msg;
  lastNotificationTime = Date.now();
}
