/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSearchParams, useLocation } from "react-router-dom";
import { commonLogoutFunc } from "../../../utils/share";
import Loader from "../../../layout/loader";
import {
  DeleteUser,
  activateOrDeactivateUser,
  getUsersList,
  sendEmail,
} from "../../../services/users.services";
import { useRef } from "react";
import CustomModal from "../../common/CustomModal/CustomModal";
import Textfield from "../../common/TextFeild/TextFeild";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const UserManagement = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [plan, setPlan] = useState("");
  const debounceTimeout = useRef();
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState("");
  const [id, setId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const login = useSelector((state) => state?.login);
  const [pageCountData, setPageCountData] = useState({
    incomplete: 0,
    deactive: 0,
    verified: 0,
    unverified: 0,
  });

  useEffect(() => {
    if (query !== "" && query.get("page") !== "") {
      var current_page = query.get("page");
      var searchData = search;
      setPage(parseInt(current_page));
      if (location.state.url !== location.pathname) {
        searchData = "";
        setSearch("");
      }
      if (location.pathname === "/user_management") {
        UserList("VERIFIED", current_page, 10, searchData, plan);
      } else if (location.pathname === "/verify_user") {
        UserList("UNVERIFIED", current_page, 10, searchData, "");
      } else if (location.pathname === "/incomplete_profiles") {
        UserList("INCOMPLETE", current_page, 10, searchData, "");
      } else if (location.pathname === "/deactive_user") {
        UserList("DEACTIVE", current_page, 10, searchData, "");
      }
    } else if (location.pathname === "/user_management") {
      navigate(`/user_management?page=${1}`, {
        state: { url: location.pathname },
      });
    } else if (location.pathname === "/verify_user") {
      navigate(`/verify_user?page=${1}`, { state: { url: location.pathname } });
    } else if (location.pathname === "/incomplete_profiles") {
      navigate(`/incomplete_profiles?page=${1}`, {
        state: { url: location.pathname },
      });
    } else if (location.pathname === "/deactive_user") {
      navigate(`/deactive_user?page=${1}`, {
        state: { url: location.pathname },
      });
    }
  }, [query, location.pathname, refresh]);

  const formik = useFormik({
    initialValues: {
      subject: "",
      content: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("This field is mandatory"),
      content: Yup.string().required("This field is mandatory"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setModalShow(false);
      await SendEmail(values);
      resetForm();
    },
  });

  const handleClose = () => {
    setShowDeactivate(false);
  };

  const deactivateRequest = () => {
    setShowDeactivate(true);
    setDeactivateReason("");
  };

  const handleSearch = (s) => {
    clearTimeout(debounceTimeout.current); // Clear the previous timeout
    setSearch(s.trimStart());

    debounceTimeout.current = setTimeout(() => {
      if (location.pathname === "/user_management") {
        navigate(`/user_management?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("VERIFIED", 1, 10, s, plan);
        setPage(1);
      } else if (location.pathname === "/verify_user") {
        navigate(`/verify_user?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("UNVERIFIED", 1, 10, s, "");
        setPage(1);
      } else if (location.pathname === "/incomplete_profiles") {
        navigate(`/incomplete_profiles?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("INCOMPLETE", 1, 10, s, "");
        setPage(1);
      } else if (location.pathname === "/deactive_user") {
        navigate(`/deactive_user?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("DEACTIVE", 1, 10, s, "");
        setPage(1);
      }
    }, 1000);
  };
  const deleteUser = async () => {
    setLoading(true);
    try {
      await DeleteUser(selectedId);
      setDeleteModalShow(false);
      setRefresh((prev) => !prev);
      setLoading(false);
      toast.success("User Deleted Successfully");
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };
  const handleKeySearch = (e) => {
    if (
      e.key === "Enter" ||
      e.keyCode === 13 ||
      e.key === "Go" ||
      e.key === "Search" ||
      e.key === "Next"
    ) {
      setPage(1);
      if (location.pathname === "/user_management") {
        navigate(`/user_management?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("VERIFIED", 1, 10, search.trimEnd(), plan);
        setPage(1);
      } else if (location.pathname === "/verify_user") {
        navigate(`/verify_user?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("UNVERIFIED", 1, 10, search.trimEnd(), "");
        setPage(1);
      } else if (location.pathname === "/incomplete_profiles") {
        navigate(`/incomplete_profiles?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("INCOMPLETE", 1, 10, search.trimEnd(), "");
        setPage(1);
      } else if (location.pathname === "/deactive_user") {
        navigate(`/deactive_user?page=${1}`, {
          state: { url: location.pathname },
        });
        UserList("DEACTIVE", 1, 10, search.trimEnd(), "");
        setPage(1);
      }
    }
  };

  const UserList = async (status, page, limit, search, plan) => {
    toast.dismiss();
    setLoading(true);
    // API call
    const response = await getUsersList(status, page, limit, search, plan);
    setData(response.data.records);
    setCount(response.data.totalCount);
    setLoading(false);
    setPageCountData({
      incomplete: response?.data?.incomplete,
      deactive: response?.data?.deactive,
      verified: response?.data?.verified,
      unverified: response?.data?.unverified,
    });
  };
  const SendEmail = async (data) => {
    setLoading(true);
    let LocationStatus = "";
    if (location.pathname === "/user_management") {
      LocationStatus = "VERIFIED";
    } else if (location.pathname === "/verify_user") {
      LocationStatus = "UNVERIFIED";
    } else if (location.pathname === "/incomplete_profiles") {
      LocationStatus = "INCOMPLETE";
    } else if (location.pathname === "/deactive_user") {
      LocationStatus = "DEACTIVE";
    }
    const { data: { status } = {}, message } = await sendEmail(
      LocationStatus,
      data
    );
    if (status) {
      toast.success("Email Sent Successfully");
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const userStatus = (e, id, status) => {
    e.preventDefault();
    toast.dismiss();
    if (status === "2" && deactivateReason.trim() === "") {
      toast.error("Please enter the deactivation reason.");
      return false;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        status === "1" ? "User will be activated" : "User will be deactivated",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF6700",
      cancelButtonColor: "#2B2B2B",
      confirmButtonText: status === "1" ? "Activate" : "Deactivate",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let params;
        if (status === "1") {
          params = {
            action: "activate",
          };
        } else {
          params = {
            action: "deactivate",
            reason: deactivateReason,
          };
        }

        try {
          await activateOrDeactivateUser(id, params);

          var current_page = query.get("page");
          var searchData = search;
          if (location.pathname === "/user_management") {
            UserList("VERIFIED", current_page, 10, searchData, plan);
          } else if (location.pathname === "/deactive_user") {
            UserList("DEACTIVE", current_page, 10, searchData, "");
          }
          handleClose();
          setLoading(false);
          Swal.fire({
            title: status === "1" ? "Activate" : "Deactivate",
            text:
              status === "1"
                ? "User has been activated successfully."
                : "User has been deactivated.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF6700",
          });
        } catch (error) {
          setLoading(false);
          if (error.response && error.response.status === 401) {
            sessionStorage.clear();
            commonLogoutFunc();
            toast.error("Your session has expired. Please login again!");
            return;
          }
        }
      }
    });
  };
  const handlePlanSearch = (plan) => {
    UserList("VERIFIED", 1, 10, search.trimEnd(), plan);
    setPage(1);
    setPlan(plan);
  };

  const renderHeader = (type) => {
    switch (type) {
      case "/user_management":
        return `Verified (${pageCountData.verified})`;
      case "/verify_user":
        return `Unverified (${pageCountData.unverified})`;
      case "/incomplete_profiles":
        return `Incomplete (${pageCountData.incomplete})`;
      case "/deactive_user":
        return `Deactivated (${pageCountData.deactive})`;
      default:
        return "";
    }
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head">
                <div className="flex_between">
                  <h2 className="mb-0 usersHeaderWrap">
                    {renderHeader(location?.pathname)}
                  </h2>
                  {login?.user?.staticRole === 1 && (
                    <Button
                      className="btn2"
                      variant="unset"
                      onClick={() => setModalShow(true)}
                    >
                      Send an email
                    </Button>
                  )}
                </div>
              </div>
              <div
                className="form-group has-search search-clear"
                style={
                  login?.user?.staticRole === 2 ? { marginTop: "10px" } : {}
                }
              >
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                  onKeyDown={(e) => handleKeySearch(e)}
                />
              </div>
              {location.pathname === "/user_management" && (
                <div className="form-group text-right plan-search">
                  <Form.Select
                    aria-label="Default select example"
                    value={plan}
                    onChange={(e) => handlePlanSearch(e.target.value)}
                  >
                    <option value="">All Users</option>
                    <option value="gold">Gold Users</option>
                    <option value="platinum">Platinum Users</option>
                    <option value="rhodium">Rhodium Users</option>
                  </Form.Select>
                </div>
              )}
              <div className="table_box text-center">
                {data.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USERNAME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        {location.pathname === "/incomplete_profiles" && (
                          <th>First Reminder</th>
                        )}
                        {location.pathname === "/incomplete_profiles" && (
                          <th>Second Reminder</th>
                        )}
                        {location.pathname === "/incomplete_profiles" && (
                          <th>Third Reminder</th>
                        )}
                        {location.pathname === "/incomplete_profiles" && (
                          <th>Fourth Reminder</th>
                        )}
                        <th>STATUS</th>
                        {location.pathname === "/user_management" && (
                          <th>MEMBERSHIP</th>
                        )}
                        <th>DETAILS</th>
                        {location.pathname === "/user_management" ? (
                          <>
                            <th>DEACTIVATE</th>
                          </>
                        ) : (
                          ""
                        )}
                        {location.pathname === "/deactive_user" ? (
                          <>
                            <th>ACTIVATE</th>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr>
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            {(page - 1) * 10 + (index + 1)}
                          </td>
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            {d.userName}
                          </td>
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            {d.firstName1
                              ? d.firstName1 + " " + d.lastName1
                              : "-"}
                          </td>
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            {d.email ? d.email : "-"}
                          </td>
                          {location.pathname === "/incomplete_profiles" && (
                            <td
                              className={`${d?.isVisible ? "" : "greyOutText"}`}
                            >
                              {d.reminderSent < 1 ? "Pending" : "Sent"}
                            </td>
                          )}
                          {location.pathname === "/incomplete_profiles" && (
                            <td
                              className={`${d?.isVisible ? "" : "greyOutText"}`}
                            >
                              {d.reminderSent > 1 ? "Sent" : "Pending"}
                            </td>
                          )}
                          {location.pathname === "/incomplete_profiles" && (
                            <td
                              className={`${d?.isVisible ? "" : "greyOutText"}`}
                            >
                              {d.reminderSent > 2 ? "Sent" : "Pending"}
                            </td>
                          )}
                          {location.pathname === "/incomplete_profiles" && (
                            <td
                              className={`${d?.isVisible ? "" : "greyOutText"}`}
                            >
                              {d.reminderSent === 4 ? "Sent" : "Pending"}
                            </td>
                          )}
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            {location.pathname === "/incomplete_profiles" ||
                            location.pathname === "/verify_user" ||
                            location.pathname === "/deactive_user" ? (
                              <img
                                src={require("../../../assets/images/Vector.png")}
                                width={15}
                                style={{
                                  cursor:
                                    location.pathname === "/incomplete_profiles"
                                      ? "pointer"
                                      : "",
                                }}
                                onClick={() => {
                                  if (
                                    location.pathname === "/incomplete_profiles"
                                  ) {
                                    setDeleteModalShow(true);
                                    setSelectedId(d?.id);
                                  }
                                }}
                              />
                            ) : (
                              <img
                                src={require("../../../assets/images/Group.png")}
                                width={15}
                              />
                            )}
                          </td>
                          {location.pathname === "/user_management" && (
                            <td
                              className={`${d?.isVisible ? "" : "greyOutText"}`}
                            >
                              {d.subscription}
                            </td>
                          )}
                          <td
                            className={`${d?.isVisible ? "" : "greyOutText"}`}
                          >
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="btn-view-1">
                                  View User Details
                                </Tooltip>
                              }
                            >
                              <Button
                                onClick={() => {
                                  if (
                                    location.pathname === "/user_management"
                                  ) {
                                    navigate(`/user_details/${d.id}`, {
                                      state: { url: location.pathname },
                                    });
                                  }
                                  if (location.pathname === "/verify_user") {
                                    navigate(`/unverified_user/${d.id}`, {
                                      state: { url: location.pathname },
                                    });
                                  }
                                  if (
                                    location.pathname === "/incomplete_profiles"
                                  ) {
                                    navigate(`/incomplete_user/${d.id}`, {
                                      state: { url: location.pathname },
                                    });
                                  }
                                  if (location.pathname === "/deactive_user") {
                                    navigate(`/deactive_user/${d.id}`, {
                                      state: { url: location.pathname },
                                    });
                                  }
                                }}
                                className="btn border-0 bg-transparent"
                                variant="unset"
                              >
                                <img
                                  src={
                                    require("../../../assets/images/eye.svg")
                                      .default
                                  }
                                  alt=""
                                  width="25px"
                                />
                              </Button>
                            </OverlayTrigger>
                          </td>
                          {location.pathname === "/user_management" ? (
                            <td>
                              <div className="togglebtn">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {d.isActive
                                        ? "Deactivate User"
                                        : "Activate User"}
                                    </Tooltip>
                                  }
                                >
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={d.isActive}
                                      onChange={(e) => {
                                        setId(d.id);
                                        if (!d.isActive) {
                                          userStatus(e, d.id, "1");
                                        } else {
                                          deactivateRequest();
                                        }
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </OverlayTrigger>
                              </div>
                            </td>
                          ) : (
                            ""
                          )}
                          {location.pathname === "/deactive_user" ? (
                            <td>
                              <div className="togglebtn">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {d.isActive
                                        ? "Deactivate User"
                                        : "Activate User"}
                                    </Tooltip>
                                  }
                                >
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={d.isActive}
                                      onChange={(e) => {
                                        setId(d.id);
                                        if (!d.isActive) {
                                          userStatus(e, d.id, "1");
                                        } else {
                                          deactivateRequest();
                                        }
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </OverlayTrigger>
                              </div>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(event) => {
                        setPage(event);
                        if (location.pathname === "/user_management") {
                          navigate(`/user_management?page=${event}`, {
                            state: { url: location.pathname },
                          });
                          return;
                        } else if (location.pathname === "/verify_user") {
                          navigate(`/verify_user?page=${event}`, {
                            state: { url: location.pathname },
                          });
                          return;
                        } else if (
                          location.pathname === "/incomplete_profiles"
                        ) {
                          navigate(`/incomplete_profiles?page=${event}`, {
                            state: { url: location.pathname },
                          });
                          return;
                        } else if (location.pathname === "/deactive_user") {
                          navigate(`/deactive_user?page=${event}`, {
                            state: {
                              url: location.pathname,
                            },
                          });
                          return;
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <>
              <CustomModal
                show={modalShow}
                heading="Send an email"
                onHide={() => {
                  setModalShow(false);
                  formik.resetForm();
                }}
                body={
                  <form id="emailForm" onSubmit={formik.handleSubmit}>
                    <div>
                      <Textfield
                        label="Subject"
                        wordLimit={40}
                        placeholder="Enter subject here..."
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        name="subject"
                        rows={2}
                        maxLength={40}
                        error={
                          formik.touched.subject && !!formik.errors.subject
                        }
                        helperText={formik.errors.subject}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Textfield
                        label="Content"
                        wordLimit={1000}
                        placeholder="Enter content here..."
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        name="content"
                        rows={6}
                        maxLength={1000}
                        error={
                          formik.touched.content && !!formik.errors.content
                        }
                        helperText={formik.errors.content}
                      />
                    </div>
                  </form>
                }
                footer={
                  <div className="btn-gap">
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        setModalShow(false);
                      }}
                      variant="outline"
                      className="btn-black2"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-orange2"
                      type="submit"
                      form="emailForm"
                    >
                      Send
                    </Button>
                  </div>
                }
              />
            </>
          </Col>
        </Row>
        <Modal
          show={showDeactivate}
          onHide={() => handleClose()}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Deactivate User</Modal.Title>
          </Modal.Header>
          <Modal.Body>Reason for deactivating user</Modal.Body>
          <Form>
            <Form.Group className="ml-2">
              <Form.Control
                style={{ maxWidth: "480px" }}
                as="textarea"
                rows={5}
                placeholder="Enter deactivation reason"
                maxLength={350}
                value={deactivateReason}
                onChange={(e) => setDeactivateReason(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
            <Button
              className="btn-black"
              variant="unset"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="btn-orange"
              variant="unset"
              onClick={(e) => userStatus(e, id, "2")}
            >
              Deactivate
            </Button>
          </Modal.Footer>
        </Modal>
        <CustomModal
          show={deleteModalShow}
          hideCross
          size="small"
          onHide={() => setDeleteModalShow(false)}
          body={
            <div style={{ textAlign: "center" }}>
              They will be permanently deleted. Are you sure you want to
              continue?
            </div>
          }
          footer={
            <div className="btn-gap">
              <Button
                onClick={() => {
                  setDeleteModalShow(false);
                }}
                variant="outline"
                className="btn-black2"
                type="button"
              >
                No
              </Button>
              <Button className="btn-orange2" onClick={() => deleteUser()}>
                Yes
              </Button>
            </div>
          }
        />
      </Container>
    </section>
  );
};

export default UserManagement;
