import React from "react";

const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clipPath="url(#clip0_12120_6529)">
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="#FF6700"
          fillOpacity="0.17"
        />
        <path
          d="M21.4 9H8.6C7.72 9 7.008 9.675 7.008 10.5L7 19.5C7 20.325 7.72 21 8.6 21H21.4C22.28 21 23 20.325 23 19.5V10.5C23 9.675 22.28 9 21.4 9ZM21.08 12.1875L15.424 15.5025C15.168 15.6525 14.832 15.6525 14.576 15.5025L8.92 12.1875C8.83978 12.1453 8.76954 12.0882 8.71351 12.0198C8.65749 11.9514 8.61685 11.8731 8.59406 11.7895C8.57127 11.706 8.5668 11.619 8.58093 11.5337C8.59505 11.4485 8.62747 11.3669 8.67623 11.2937C8.72498 11.2206 8.78906 11.1575 8.86458 11.1082C8.9401 11.059 9.0255 11.0246 9.1156 11.0072C9.2057 10.9898 9.29863 10.9897 9.38876 11.007C9.4789 11.0242 9.56437 11.0584 9.64 11.1075L15 14.25L20.36 11.1075C20.4356 11.0584 20.5211 11.0242 20.6112 11.007C20.7014 10.9897 20.7943 10.9898 20.8844 11.0072C20.9745 11.0246 21.0599 11.059 21.1354 11.1082C21.2109 11.1575 21.275 11.2206 21.3238 11.2937C21.3725 11.3669 21.405 11.4485 21.4191 11.5337C21.4332 11.619 21.4287 11.706 21.4059 11.7895C21.3832 11.8731 21.3425 11.9514 21.2865 12.0198C21.2305 12.0882 21.1602 12.1453 21.08 12.1875Z"
          fill="#FF6700"
        />
      </g>
      <defs>
        <clipPath id="clip0_12120_6529">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailIcon;
