import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { getDashboardDetails } from "../../../services/dashboard.services";
import Loader from "../../../layout/loader";
import { commonLogoutFunc } from "../../../utils/share";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getDashboardData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  var users = {
    labels: ["FREE", "GOLD", "PLATINUM", "RHODIUM"],
    datasets: [
      {
        label: "UNLCKED USERS",
        data: [data.Free, data.Gold, data.Platinum, data.Rhodium],
        backgroundColor: ["#F1D1AA", "#C4A772", "#B79027", "#000000"],
      },
    ],
  };

  const getDashboardData = async () => {
    setLoading(true);
    try {
      const response = await getDashboardDetails();
      setLoading(false);
      setData(response.data.data);
      if (response.status === 401) {
        sessionStorage.clear();
        commonLogoutFunc();
        toast.error("Your session has been expired, Please login again!");
        return;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <Row className="mt-4">
                <Col md={6}>
                  <div className="pricingTable">
                    <div className="pricingTable-header">
                      <span className="heading" style={{ color: "#F1D1AA" }}>
                        Free Membership
                      </span>
                      <hr />
                    </div>
                    <div className="pricing-plans">
                      <span style={{ fontSize: "19px", color: "#F1D1AA" }}>
                        {data.Free}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pricingTable">
                    <div className="pricingTable-header">
                      <span className="heading" style={{ color: "#B79027" }}>
                        Premium Membership
                      </span>
                      <hr />
                    </div>
                    <div className="pricing-plans">
                      <span style={{ fontSize: "19px", color: "#B79027" }}>
                        {data.Gold + data.Platinum + data.Rhodium}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <Bar data={users} options={options} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dashboard;
