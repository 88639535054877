/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSearchParams, useLocation } from "react-router-dom";
import Loader from "../../../layout/loader";
import { deleteAdmin, getAllAdmin } from "../../../services/admin.services";
import Swal from "sweetalert2";
import { commonLogoutFunc } from "../../../utils/share";

const Admin = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (query !== '' && query.get('page') !== '') {
            var current_page = query.get('page')
            var searchData = search
            setPage(parseInt(current_page))
            if (location.state?.url !== location.pathname) {
                searchData = ""
                setSearch("")
            }
            if (location.pathname === '/admin') {
                AdminList(current_page, 10, searchData);
            }
        }
        else if (location.pathname === '/admin') {
            navigate(`/admin?page=${1}`, { state: { url: location.pathname } })
        }
    }, [query, location.pathname])

    const handleSearch = (s) => {
        setSearch(s.trimStart());
        if (s === '') {
            if (location.pathname === '/admin') {
                navigate(`/admin?page=${1}`, { state: { url: location.pathname } })
                AdminList(1, 10, s);
                setPage(1)
            }
        }
    }

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            setPage(1)
            if (location.pathname === '/admin') {
                navigate(`/admin?page=${1}`, { state: { url: location.pathname } })
                AdminList(1, 10, search.trimEnd());
                setPage(1)
            }
        }
    }

    const AdminList = async (page, limit, search) => {
        toast.dismiss();
        setLoading(true);
        // API call
        const response = await getAllAdmin(page, limit, search);
        console.log("response", response);
        setData(response.data.data.records);
        setCount(response.data.data.totalCount);
        setLoading(false);
    };

    const deleteUser = (id) => {
        toast.dismiss()
        Swal.fire({
            title: 'Are you sure?',
            text: 'Admin will be deleted',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF6700',
            cancelButtonColor: '#2B2B2B',
            confirmButtonText: 'Delete'
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const response = await deleteAdmin(id);
                    console.log("response", response);
                    setData(response.data)
                    setLoading(false);
                    Swal.fire({
                        title: 'Deleted',
                        text: 'Admin has been deleted',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#FF6700'
                    })

                } catch (error) {
                    if (error.response.status === 401) {
                        sessionStorage.clear();
                        commonLogoutFunc();
                        toast.error("Your session has been expired, Please login again!")
                        return
                    }
                }
            }
        })
    }

    return (
        <section className="botmanagement_section">
            <Loader promiseInProgress={isLoading} />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="sale_graph transaction_box mb-3">
                            <div className="trans_head mb-3">
                                <div className="btn_title_row">
                                    <h2 className="mb-0">Admin Management</h2>
                                </div>
                            </div>
                            <div className="form-group has-search search-clear">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input type="text" className="form-control" placeholder="Search" value={search} onChange={(e) => handleSearch(e.target.value)} onKeyDown={(e) => handleKeySearch(e)} />
                                <div className="text-right">
                                    <Button className="btn-orange" variant="unset" onClick={() => navigate('/admin/add')}>Add Admin <i className="fa fa-plus"></i></Button>
                                </div>
                            </div>
                            <div className="table_box text-center">
                                {data.length > 0 && !isLoading ?
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>NO.</th>
                                                <th>USERNAME</th>
                                                <th>FULL NAME</th>
                                                <th>EMAIL</th>
                                                <th>DELETE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((d, index) =>
                                                <tr>
                                                    <td>{((page - 1) * 10) + (index + 1)}</td>
                                                    <td>{d.userName ? d.userName : '-'}</td>
                                                    <td>{d.firstName ? d.firstName + ' ' + d.lastName : '-'}</td>
                                                    <td>{d.email ? d.email : '-'}</td>
                                                    {location.pathname === '/admin' ?
                                                        <td>

                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="btn-view-1">Delete Admin</Tooltip>
                                                                }
                                                            >
                                                                <Button onClick={() => deleteUser(d.id, index)} className="btn border-0 bg-transparent" variant="unset">
                                                                    <img src={require('../../../assets/images/dlte.svg').default} alt="" width="25px" />
                                                                </Button>
                                                            </OverlayTrigger>

                                                        </td>
                                                        : ''
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    :
                                    <div className="msg-data text-center py-3">
                                        {
                                            !isLoading
                                                ?
                                                <h4>No Data Found</h4>
                                                : <h6>Please wait...</h6>
                                        }
                                    </div>
                                }
                            </div>
                            {!isLoading &&
                                <>
                                    {count > 10 &&
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={10}
                                            totalItemsCount={count}
                                            pageRangeDisplayed={5}
                                            prevPageText="Previous"
                                            nextPageText="Next"
                                            onChange={(event) => {
                                                setPage(event)
                                                if (location.pathname === '/admin') {
                                                    navigate(`/admin?page=${event}`, { state: { url: location.pathname } })
                                                    return
                                                }
                                            }}
                                        />}
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    );

}

export default Admin;
