const NotFound = () => {

    return (
        <div>
            <img src="https://i.imgur.com/qIufhof.png" height="450" alt=""/>
            <h2>404 (PAGE NOT FOUND)</h2>
        </div>
    );

}

export default NotFound;
