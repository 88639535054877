import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../components/Views/Dashboard/Dashboard";
import Login from "../components/Views/Login/Login";
import NotFound from "../components/NotFound(404)";
import UserManagement from "../components/Views/UserManagement/UserManagement";
import UserDetails from "../components/Views/UserDetails/UserDetails";
import Profile from "../components/Views/Profile/Profile";
import PromoCodes from "../components/Views/PromoCodes/PromoCodes";
import Karma from "../components/Views/Karma/Karma";
import { LawEnforce } from "../components/Views/LawEnforcement/LawEnforce";
import ContactUs from "../components/Views/PressCollab/ContactUs";
import Admin from "../components/Views/Admin/Admin";
import AdminForm from "../components/Views/Admin/AdminForm";
import SearchPage from "../components/Views/SearchPage/SearchPage";
import ResetPassword from "../components/Views/ResetPassword/ResetPassword";
import Layout from "../layout/Layout";
import { useSelector } from "react-redux";
import Contact from "../components/Views/Contact/Contact";
import Gosspel from "../components/Views/Gosspel/Gosspel";
import UserComments from "../components/Views/Gosspel/UserComments";
import InAppCommunication from "../components/Views/InAppCommunication/InAppCommunication";
import DeletedUsers from "../components/Views/DeletedUsers/DeletedUsers";
import FoundMyMatch from "../components/Views/DeletedUsers/FoundMyMatch";
import FoundMyPersonElesewhere from "../components/Views/DeletedUsers/FoundMyPersonElesewhere";
import PeopleStillPlayGames from "../components/Views/DeletedUsers/PeopleStillPlayGames";
import NeedToBeAloneForBit from "../components/Views/DeletedUsers/NeedToBeAloneForBit";
import DontLikeApp from "../components/Views/DeletedUsers/DontLikeApp";
import UsersResponse from "../components/Views/InAppCommunication/UsersResponse";
import CommentDetails from "../components/Views/InAppCommunication/CommentDetails";
import DeletedByAdmin from "../components/Views/DeletedUsers/DeletedByAdmin";

const SecureRoutes = () => {
  const loginData = useSelector((state) => state?.login)
  const isAuthenticated = loginData?.token;
  const staticRole = loginData?.user?.staticRole;
  return (
    <>
      {
        isAuthenticated && staticRole === 1 ?
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/verify_user" element={<UserManagement />} />
              <Route path="/deactive_user" element={<UserManagement />} />
              <Route path="/deactive_user/:id" element={<UserDetails />} />
              <Route path="/user_details/:id" element={<UserDetails />} />
              <Route path="/incomplete_user/:id" element={<UserDetails />} />
              <Route path="/unverified_user/:id" element={<UserDetails />} />
              <Route path="/user_management" element={<UserManagement />} />
              <Route path="/incomplete_profiles" element={<UserManagement />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/app_communication" element={<InAppCommunication />} />
              <Route path="/promo" element={<PromoCodes />} />
              <Route path="/admin/add" element={<AdminForm />} />
              <Route path="/karma" element={<Karma />} />
              <Route path="/law_enforce" element={<LawEnforce />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/gosspel" element={<Gosspel />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/user_comments" element={<UserComments />} />
              <Route path="/deleted-users" element={<DeletedUsers />} />
              <Route path="/found_my_match" element={<FoundMyMatch />} />
              <Route
                path="/found_my_person_elsewhere"
                element={<FoundMyPersonElesewhere />}
              />
              <Route
                path="/people_still_play_games"
                element={<PeopleStillPlayGames />}
              />
              <Route path="/need_to_be_alone" element={<NeedToBeAloneForBit />} />
              <Route path="/dont_like_app" element={<DontLikeApp />} />
              <Route path="/deleted_by_admin" element={<DeletedByAdmin />} />
              <Route path="/users_response/:id" element={<UsersResponse />} />
              <Route
                path="/users_comment_details/:id/:responseId"
                element={<CommentDetails />}
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes >
          : isAuthenticated && staticRole === 2 ? <Routes>
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path="/" element={<Layout />} >
              <Route path='/verify_user' element={<UserManagement />} />
              <Route path='/unverified_user/:id' element={<UserDetails />} />
              <Route path='*' element={<NotFound />} />
            </Route >
          </Routes > :
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='*' element={<Navigate to="/" />} />
            </Routes>
      }
    </>
  );

}

export default SecureRoutes;
