import { axiosInstance } from '../config/api';

export const getAlLawEnforcement = (page, limit, search) => {
    let url = '/contact/law-enforcement?';
    url += `page=${page - 1 ?? 0}&limit=${limit ?? 10}&search=${search}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};

export const getAllPressCollab = (page, limit, search) => {
    let url = '/contact/press-or-collab?';
    url += `page=${page - 1 ?? 0}&limit=${limit ?? 10}&search=${search}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};

export const updatePressCollabStatus = (id, status) => {
    let url = `/contact/press-or-collab/${id}?status=${status}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .put(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};

export const updateLawEnforcementStatus = (id, status) => {
    let url = `/contact/law-enforcement/${id}?status=${status}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .put(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};