/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Pagination from "react-js-pagination";
import * as Yup from "yup";
import moment from "moment";
import {
  DeleteArticle,
  EmailDeletedGosspel,
  getGosspelComments,
  getGosspelEmailDataById,
} from "../../../services/gosspel.services";
import Loader from "../../../layout/loader";
import CustomModal from "../../common/CustomModal/CustomModal";
import DeleteIcon from "../../../assets/images/Icons/DeleteIcon";
import MailIcon from "../../../assets/images/Icons/MailIcon";
import Textfield from "../../common/TextFeild/TextFeild";
import emailSent from "../../../assets/images/emailSent.svg";
const modalProps = {
  emailPopup: false,
  emailSentPopup: false,
};

const UserComments = () => {
  const [commentsId, setCommentsId] = useState();
  const [gosspelCommentListing, setGosspelCommentListing] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedComment, setSelectedComment] = useState("");
  const [selectedDeleteComment, setSelectedDeleteComment] = useState("");
  const [selectedEmailComment, setSelectedEmailComment] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  // const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [openModal, setOpenModal] = useState(modalProps);
  const [emailData, setEmailData] = useState({});
  const navigate = useNavigate();

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const truncateComment = (comment, maxLength) => {
    if (comment.length <= maxLength) {
      return comment;
    }
    return (
      <>
        {comment.slice(0, maxLength)}
        <span>... </span>
        <span
          style={{ color: "orange", cursor: "pointer" }}
          onClick={() => handleShowComment(comment)}
        >
          view more
        </span>
      </>
    );
  };

  const handleShowComment = (comment) => {
    setSelectedComment(comment);
    setShowComments(true);
  };

  const deleteComment = async (id) => {
    setDeleteConfirmation(false);
    setLoading(true);
    try {
      await DeleteArticle(id);
      getGosspelData(commentsId, page);
      setLoading(false);
      toast.success("Gosspel comment deleted successfully");
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      content: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("This field is mandatory"),
      content: Yup.string().required("This field is mandatory"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await EmailGosspel(values);
      resetForm();
      setOpenModal(modalProps);
    },
  });

  const EmailGosspel = async (data) => {
    setLoading(true);
    try {
      await EmailDeletedGosspel(selectedEmailComment, data);
      setLoading(false);
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };

  const EmailDataGosspel = async (idTemp) => {
    setLoading(true);
    try {
      const response = await getGosspelEmailDataById(idTemp);
      setEmailData(response?.data?.data);
      setLoading(false);
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };

  const getGosspelData = async (commentsId, page) => {
    setLoading(true);
    try {
      const response = await getGosspelComments(commentsId, page);
      const {
        data: {
          data: { records, totalCount, gosspelTitle },
        },
      } = response;
      setTitle(gosspelTitle);
      setGosspelCommentListing(records);
      setCount(totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  useEffect(() => {
    const Id = localStorage.getItem("commentsId");
    setCommentsId(Id);
  }, []);

  useEffect(() => {
    if (commentsId) {
      getGosspelData(commentsId, page);
    }
  }, [commentsId, page, openModal.emailPopup]);

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  // const filteredComments = gosspelCommentListing.filter((comment) =>
  //   comment.userName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const handleOpenModal = (type) => {
    setOpenModal({ ...modalProps, [type]: true });
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="back-heading">
                  <div>
                    <img
                      onClick={() => {
                        localStorage.removeItem("commentsId");
                        navigate("/gosspel");
                      }}
                      src={
                        require("../../../assets/images/leftArrow.svg").default
                      }
                      alt=""
                      width="25px"
                    />
                  </div>
                  <h2 className="mb-0">User Comments</h2>
                </div>
              </div>
              {/* <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div> */}
              <div className="title-heading">
                <div className="title">Title</div>
                <div className="sub-title">{title}</div>
              </div>
              <div className="title" style={{ marginTop: "24px" }}>
                User Comments ({count})
              </div>
              <div
                className="table_box text-center"
                style={{ marginTop: "12px" }}
              >
                {/* {filteredComments.length > 0 && !isLoading ? ( */}
                {gosspelCommentListing.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USERNAME</th>
                        <th>COMMENT DATE</th>
                        <th>COMMENT</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gosspelCommentListing.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => handleShowComment(item.comment)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td>
                            {item.isAnonymous ? "Anonymous" : item?.userName}
                          </td>
                          <td>{formatDate(item?.createdAt)}</td>
                          <td
                            className={`${
                              item.deletedAt !== null ? "deleted-comment" : ""
                            }`}
                          >
                            {truncateComment(item.comment, 60)}
                          </td>
                          <td>
                            {item.deletedAt === null ? (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmation(true);
                                  setSelectedDeleteComment(item?.id);
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">Delete</Tooltip>
                                  }
                                >
                                  <div>
                                    <DeleteIcon />
                                  </div>
                                </OverlayTrigger>
                              </div>
                            ) : item?.isEmailSent === false ? (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedEmailComment(item?.id);
                                  handleOpenModal("emailPopup");
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      Send an email
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <MailIcon />
                                  </div>
                                </OverlayTrigger>
                              </div>
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  EmailDataGosspel(item?.id);
                                  handleOpenModal("emailSentPopup");
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      Email Sent
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <img src={emailSent} alt="mailSent" />
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && gosspelCommentListing.length > 0 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(e) => setPage(e)}
                    />
                  )}
                </>
              )}
            </div>
            <CustomModal
              show={showComments}
              heading="User's Comment"
              onHide={() => {
                setShowComments(false);
              }}
              underline
              body={<div>{selectedComment}</div>}
            />
            <CustomModal
              show={deleteConfirmation}
              hideCross
              size="small"
              onHide={() => {
                setDeleteConfirmation(false);
              }}
              body={
                <div className="text-center">
                  Are you sure you want to delete this comment?
                </div>
              }
              footer={
                <div className="btn-gap" style={{ width: "100%" }}>
                  <Button
                    className="btn-black2"
                    style={{ width: "100%" }}
                    variant="unset"
                    onClick={() => {
                      setDeleteConfirmation(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn-orange2"
                    style={{ width: "100%" }}
                    variant="unset"
                    onClick={async () => {
                      await deleteComment(selectedDeleteComment);
                    }}
                  >
                    Yes
                  </Button>
                </div>
              }
            />
            <CustomModal
              show={openModal.emailPopup}
              heading="Send an email"
              onHide={() => {
                setOpenModal(modalProps);
                formik.resetForm();
              }}
              body={
                <form id="emailForm" onSubmit={formik.handleSubmit}>
                  <div>
                    <Textfield
                      label="Subject"
                      wordLimit={40}
                      placeholder="Enter subject here..."
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      name="subject"
                      rows={2}
                      maxLength={100}
                      error={formik.touched.subject && !!formik.errors.subject}
                      helperText={formik.errors.subject}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Textfield
                      label="Content"
                      wordLimit={1000}
                      placeholder="Enter content here..."
                      value={formik.values.content}
                      onChange={formik.handleChange}
                      name="content"
                      rows={6}
                      maxLength={1000}
                      error={formik.touched.content && !!formik.errors.content}
                      helperText={formik.errors.content}
                    />
                  </div>
                </form>
              }
              footer={
                <div className="btn-gap" style={{ width: "300px" }}>
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setOpenModal(modalProps);
                    }}
                    variant="outline"
                    className="btn-black2"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-orange2"
                    type="submit"
                    form="emailForm"
                  >
                    Send
                  </Button>
                </div>
              }
            />
            <CustomModal
              show={openModal?.emailSentPopup}
              heading={<div className="emailSentPopupHeader">Email Sent</div>}
              onHide={() => {
                setOpenModal(modalProps);
              }}
              body={
                <div className="emailSentPopupBody">
                  <div className="subjectDateWrap">
                    <div className="subjectWrap">Subject</div>
                    <div className="dateWrap">
                      {moment(emailData?.emailSentDate).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <div className="subjectTextWrap">{emailData?.subject}</div>
                  <div className="contentHeader">Content</div>
                  <pre className="contentTextWrap">{emailData?.content}</pre>
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserComments;
