import { axiosInstance } from '../config/api';

export const getDashboardDetails = () => {
    let url = '/dashboard';
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)

            });
    });
};