import { ThreeDots } from 'react-loader-spinner'

const Loader = (props) => {
    return (
        <>
            {props.promiseInProgress === true ?
                <div className='loader'>
                    <ThreeDots className="loader-dev" color="#FF6700" height={80} width={80} />
                </div>
                : null}
        </>
    );
}

export default Loader;


