import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Loader from "../../../layout/loader";
import { getPromoWithParticipantsCount } from "../../../services/promo.service";
import { toast } from "react-toastify";
// import { commonLogoutFunc } from "../../../utils/share";

const PromoCodes = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (query !== "" && query.get("page") !== "") {
      var current_page = query.get("page");
      getCodes(current_page);
      setPage(parseInt(current_page));
    } else {
      navigate(`/promo?page=${1}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getCodes = async (page) => {
    setLoading(true);
    try {
      const response = await getPromoWithParticipantsCount(page, 10);
      setLoading(false);
      setData(response?.data?.data?.records);
      setCount(response?.data?.data?.totalCount);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />

      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0">Promo Codes</h2>
                </div>
              </div>
              <div className="table_box text-center">
                {data.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>PROMO CODE</th>
                        <th>COUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr>
                          <td>{(page - 1) * 10 + (index + 1)}</td>
                          <td>{d.promoCode}</td>
                          <td>{d.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
              {count > 10 && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  prevPageText="Previous"
                  nextPageText="Next"
                  onChange={(event) => {
                    setPage(event);
                    navigate(`/promo?page=${event}`);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PromoCodes;
