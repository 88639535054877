import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import CustomModal from "../CustomModal/CustomModal";
import Button from "react-bootstrap/Button";

const ImageCropper = (props) => {
  const { imageURL, open, onHide, heading, close, onCropCompleteCallback } =
    props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropImage = async () => {
    try {
      const croppedImg = await getCroppedImg(imageURL, croppedAreaPixels);
      onCropCompleteCallback(croppedImg);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CustomModal
      onHide={onHide}
      show={open}
      heading={heading}
      body={
        <div className="cropper-container">
          <Cropper
            image={imageURL}
            crop={crop}
            zoom={zoom}
            aspect={14/10}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      }
      footer={
        <div className="btn-gap" style={{ marginTop: "20px" }}>
          <Button
            onClick={close}
            variant="outline"
            className="btn-black2"
            type="button"
          >
            Cancel
          </Button>
          <Button className="btn-orange2" onClick={handleCropImage}>
            Crop
          </Button>
        </div>
      }
    />
  );
};

export default ImageCropper;
