import { axiosInstance } from "../config/api";

export const changePassword = (data) => {
  console.log("data", data);
  const url = "/admin/change-password";
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const updateProfile = (data) => {
  const url = "/admin/me";
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const getAllAdmin = (page, limit, search) => {
  let url = "/admin?";
  url += `page=${page - 1 || 0}&limit=${limit || 10}&search=${search}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const deleteAdmin = (id) => {
  let url = `/admin/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const addAdmin = (data) => {
  const url = "/admin";
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
