/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModal from "../../common/CustomModal/CustomModal.js";
import Loader from "../../../layout/loader";
import {
  AddArticle,
  EditArticle,
  GetArticleImage,
  deleteGosspel,
  getGosspelById,
  getGosspelList,
} from "../../../services/gosspel.services";
import Textfield from "../../common/TextFeild/TextFeild.jsx";
import UploadImage from "../../../assets/images/Icons/UploadImage.js";
import ImageCropper from "../../common/ImageCropper/ImageCropper.js";
import "./gosspel.css";

const Gosspel = () => {
  const navigate = useNavigate();
  const [showCropper, setShowCropper] = useState(false);
  const [showArticle, setShowArticle] = useState(false);
  const [showAddGosspel, setShowAddGosspel] = useState(false);
  const [showEditGosspel, setShowEditGosspel] = useState(false);
  const [gosspelListing, setGosspelListing] = useState([]);
  const [filteredGosspelListing, setFilteredGosspelListing] = useState([]);
  const [editData, setEditData] = useState();
  const [selectedArticle, setSelectedArticle] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [imageKey, setImageKey] = useState(null);
  const [image, setImage] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [imageURL, setImageURL] = useState("");
  const [editDataImg, setEditDataImg] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editConfirmation, setEditConfirmation] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [itemId, setItemId] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const menuRef = useRef(null);
  const [openPreview, setOpenPreview] = useState(null);
  const [selectedArticlePreview, setSelectedArticlePreview] = useState("");

  const getGosselData = async () => {
    setLoading(true);
    try {
      const response = await getGosspelList(page);
      const {
        data: {
          data: { records, totalCount },
        },
      } = response;
      setCount(totalCount);
      setGosspelListing(records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  useEffect(() => {
    getGosselData(page);
  }, [page]);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuIndex(null);
    }
  };

  const handleCropComplete = async (newImageURL) => {
    setImageURL(newImageURL);
    setShowCropper(false);

    // Fetch blob data from the new image URL
    const response = await fetch(newImageURL);
    const blobData = await response.blob();

    // Convert blob data to file
    const fileName = `cropped_${image.name}`;
    const croppedImageFile = new File([blobData], fileName, {
      type: blobData.type,
    });

    // Set the new file
    setImage(croppedImageFile);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const DeleteGosspel = async (id) => {
    setLoading(true);
    try {
      await deleteGosspel(id);
      getGosselData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  const AddGosselData = async () => {
    setLoading(true);
    const data = {
      title: formik.values.title,
      image: imageKey ? imageKey : "",
      description: formik.values.description,
    };
    try {
      await AddArticle(data);
      formik.resetForm();
      setImage(null);
      setImageURL("");
      setFileInputKey(Date.now());
      setShowConfirmation(false);
      getGosselData();
      setImageKey(null);
      setLoading(false);
      toast.success("Article published successfully");
    } catch (error) {
      formik.resetForm();
      setImage(null);
      setImageURL("");
      setFileInputKey(Date.now());
      setShowConfirmation(false);
      setLoading(false);
      toast.error(error);
    }
  };

  const EditGosspel = async () => {
    let data;

    if (editDataImg) {
      data = {
        title: formik.values.title,
        description: formik.values.description,
      };
    } else {
      data = {
        title: formik.values.title,
        image: imageKey,
        description: formik.values.description,
      };
    }

    try {
      await EditArticle(itemId, data);
      getGosselData();
    } catch (error) {
      toast.error(error);
    }
  };
  const uploadImage = async () => {
    try {
      const response = await GetArticleImage(image);
      setImageKey(response?.key);
    } catch (error) {
      toast.error(error);
    }
  };

  const truncateDescription = (description, maxLength, id, deleted) => {
    if (description.length <= maxLength) {
      return description;
    }
    return (
      <>
        {description.slice(0, maxLength)}
        <span>... </span>
        <span
          style={{ color: "orange", cursor: deleted ? "pointer" : "" }}
          onClick={() => (deleted ? handleShowArticle(id) : "")}
        >
          view more
        </span>
      </>
    );
  };

  const handleShowArticle = async (id) => {
    setMainLoading(true);
    try {
      const response = await getGosspelById(id);
      const {
        data: { data },
      } = response;
      setSelectedArticle(data);
      setSelectedArticlePreview(data);
      setMainLoading(false);
      setShowArticle(true);
    } catch (error) {
      setMainLoading(false);
      toast.error(error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageURL(reader.result);
      setShowCropper(true);
    };
    reader.readAsDataURL(file);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getGosselData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filteredList = gosspelListing.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredGosspelListing(filteredList);
    } else {
      setFilteredGosspelListing(gosspelListing);
    }
  }, [searchTerm, gosspelListing]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editData ? editData.title : "",
      description: editData ? editData.description : "",
      image: editDataImg ? editDataImg : null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is mandatory"),
      description: Yup.string().required("This field is mandatory"),
      image: Yup.mixed().required("An image is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      if (editData) {
        if (!editDataImg) {
          await uploadImage();
          setShowEditGosspel(false);
          setEditConfirmation(true);
          setLoading(false);
        } else {
          setShowEditGosspel(false);
          setEditConfirmation(true);
          setLoading(false);
        }
      } else {
        await uploadImage();
        setShowAddGosspel(false);
        setShowConfirmation(true);
        setLoading(false);
      }
    },
  });

  const editHandler = async (id) => {
    setLoading(true);
    try {
      const response = await getGosspelById(id);
      const {
        data: { data },
      } = response;
      setEditData(data);
      setEditDataImg(data?.image);
      setShowEditGosspel(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const toggleMenu = (index) => {
    setMenuIndex(menuIndex === index ? null : index);
  };

  const finalImageUrl = editDataImg ? editDataImg : imageURL;
  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Loader promiseInProgress={mainLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="flex_between">
                  <h2 className="mb-0">Goss'Pel</h2>
                  <Button
                    className="btn2"
                    variant="unset"
                    onClick={() => setShowAddGosspel(true)}
                  >
                    + Add New
                  </Button>
                </div>
              </div>
              <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="table_box text-center">
                {filteredGosspelListing.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <td>NO.</td>
                        <td>IMAGE</td>
                        <td>TITLE</td>
                        <td>DESCRIPTION</td>
                        <td>PUBLISHED ON</td>
                        <td>STATUS</td>
                        <td>COMMENTS</td>
                        <td>ACTIONS</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredGosspelListing.length > 0 &&
                        filteredGosspelListing.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() =>
                              item?.deletedAt === null
                                ? handleShowArticle(item?.id)
                                : ""
                            }
                            style={{
                              cursor: item?.deletedAt === null ? "pointer" : "",
                            }}
                          >
                            <td>{(page - 1) * 10 + (index + 1)}</td>
                            <td>
                              {item.image ? (
                                <img
                                  src={item?.image}
                                  alt="-"
                                  width="40px"
                                  height="40px"
                                  style={{ borderRadius: "4px" }}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{item?.title}</td>
                            <td>
                              {truncateDescription(
                                item?.description,
                                20,
                                item?.id,
                                item?.deletedAt === null
                              )}
                            </td>
                            <td>
                              {item?.deletedAt === null
                                ? formatDate(item?.createdAt)
                                : "-"}
                            </td>
                            <td>
                              {item?.deletedAt === null
                                ? "Published"
                                : "Deleted"}
                            </td>
                            <td>
                              {item?.deletedAt === null
                                ? item?.commentCount > 0
                                  ? item?.commentCount
                                  : "-"
                                : "-"}
                            </td>
                            <td>
                              {item.deletedAt === null ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        View comments
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={
                                        require("../../../assets/images/eye.svg")
                                          .default
                                      }
                                      alt=""
                                      width="35px"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        localStorage.setItem(
                                          "commentsId",
                                          item?.id
                                        );
                                        navigate("/user_comments");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </OverlayTrigger>
                                  <div>
                                    <img
                                      src={
                                        require("../../../assets/images/details.svg")
                                          .default
                                      }
                                      alt=""
                                      width="35px"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleMenu(index);
                                      }}
                                    />
                                    <div
                                      className="menu-dropdown"
                                      ref={menuRef}
                                    >
                                      {menuIndex === index && (
                                        <div
                                          className="selectMenu"
                                          ref={menuRef}
                                        >
                                          <div
                                            className="selectMenu-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setItemId(item?.id);
                                              editHandler(item?.id);
                                              setMenuIndex(null);
                                            }}
                                          >
                                            Edit
                                          </div>
                                          <div
                                            className="selectMenu-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setItemId(item?.id);
                                              setDeleteConfirmation(true);
                                              setMenuIndex(null);
                                            }}
                                          >
                                            Delete
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && gosspelListing.length > 0 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(e) => setPage(e)}
                    />
                  )}
                </>
              )}
            </div>
            <CustomModal
              show={deleteConfirmation}
              hideCross
              size="small"
              onHide={() => {
                setDeleteConfirmation(false);
              }}
              body={
                <div className="text-center">
                  Are you sure you want to delete this article?
                </div>
              }
              footer={
                <div className="btn-gap" style={{ width: "100%" }}>
                  <Button
                    className="btn-black2"
                    style={{ width: "100%" }}
                    variant="unset"
                    onClick={() => {
                      setDeleteConfirmation(false);
                      setItemId("");
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn-orange2"
                    style={{ width: "100%" }}
                    variant="unset"
                    onClick={async () => {
                      setDeleteConfirmation(false);
                      await DeleteGosspel(itemId);
                      setItemId("");
                    }}
                  >
                    Yes
                  </Button>
                </div>
              }
            />
            <CustomModal
              show={editConfirmation}
              hideCross
              size="small"
              body={
                <div className="text-center">
                  The changes will be visible to all the users. Are you sure you
                  want to continue?
                </div>
              }
              footer={
                <div className="btn-gap" style={{ width: "100%" }}>
                  <Button
                    className="btn-black2"
                    variant="unset"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setEditConfirmation(false);
                      setShowEditGosspel(true);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className="btn-orange2"
                    variant="unset"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      await EditGosspel(itemId);
                      setEditConfirmation(false);
                      setImage(null);
                      setImageURL("");
                      setFileInputKey(Date.now());
                      setItemId("");
                      setEditData("");
                      setEditDataImg("");
                      setShowEditGosspel(false);
                    }}
                  >
                    Yes
                  </Button>
                </div>
              }
            />
            <CustomModal
              show={showArticle}
              heading="Article"
              onHide={() => {
                setShowArticle(false);
                setSelectedArticlePreview("");
              }}
              className="article-size"
              underline
              body={
                <div>
                  <div className="title-heading">
                    <div className="title">Image</div>
                    <div
                      className="image-border"
                      onClick={() => setOpenPreview(true)}
                    >
                      <img
                        src={selectedArticle?.image}
                        alt="-"
                        style={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          height: "179px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="article-heading"
                    style={{ marginTop: "24px" }}
                  >
                    <div className="title">Title</div>
                    <div className="regular">{selectedArticle?.title}</div>
                  </div>
                  <div className="article-heading">
                    <div className="title" style={{ marginTop: "24px" }}>
                      Description
                    </div>
                    <div
                      className="regular"
                      style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
                    >
                      {selectedArticle?.description}
                    </div>
                  </div>
                </div>
              }
            />
            <CustomModal
              onHide={() => {
                setShowAddGosspel(false);
                formik.resetForm();
                setImage(null);
                setImageURL("");
                setFileInputKey(Date.now());
              }}
              show={showAddGosspel}
              heading="Add an article"
              body={
                <form
                  className="title-heading "
                  id="emailForm"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="title-heading">
                    <div className="title2">Image</div>
                    <div>
                      <label
                        htmlFor="imageUploadInput"
                        className={`${
                          image ? "image-upload-not-active" : "image-upload"
                        }`}
                      >
                        <input
                          key={fileInputKey}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            formik.setFieldValue("image", e.target.files[0]);
                            handleImageUpload(e);
                          }}
                          onBlur={formik.handleBlur}
                          style={{ display: "none" }}
                          id="imageUploadInput"
                          disabled={image ? true : false}
                        />
                        <div
                          className={`${
                            image ? "image-text-not-active" : "image-text"
                          }`}
                        >
                          {image ? (
                            <UploadImage color="#747474" />
                          ) : (
                            <UploadImage />
                          )}
                          Tap here to upload an image
                        </div>
                      </label>
                      {formik.touched.image && formik.errors.image ? (
                        <div className="required-image-error">
                          {formik.errors.image}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {imageURL && (
                    <div
                      className="image-view"
                      style={{ marginTop: "10px", width: "90px" }}
                    >
                      <img
                        src={imageURL}
                        alt="Uploaded"
                        width="88px"
                        height="79px"
                        style={{ borderRadius: "8px", cursor: "pointer" }}
                        onClick={() => setOpenPreview(true)}
                      />
                      <div
                        className="image-cross"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue("image", null);
                          setImage(null);
                          setImageURL("");
                          setFileInputKey(Date.now());
                        }}
                      >
                        <img
                          src={require("../../../assets/images/image-cross.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  <div className="title-heading ">
                    <Textfield
                      label="Title"
                      wordLimit={100}
                      placeholder="Enter title here..."
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      name="title"
                      rows={2}
                      maxLength={100}
                      error={formik.touched.title && !!formik.errors.title}
                      helperText={formik.errors.title}
                    />
                    <Textfield
                      label="Description"
                      wordLimit={4000}
                      placeholder="Enter description here..."
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      name="description"
                      rows={10}
                      maxLength={4000}
                      error={
                        formik.touched.description &&
                        !!formik.errors.description
                      }
                      helperText={formik.errors.description}
                    />
                  </div>
                </form>
              }
              footer={
                <div className="btn-gap">
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setImage(null);
                      setImageURL("");
                      setFileInputKey(Date.now());
                      setShowAddGosspel(false);
                    }}
                    variant="outline"
                    className="btn-black2"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-orange2"
                    type="submit"
                    form="emailForm"
                  >
                    Publish
                  </Button>
                </div>
              }
            />
            <CustomModal
              onHide={() => {
                setOpenPreview(false);
              }}
              show={openPreview}
              heading={"Preview"}
              body={
                <div className="imagePreviewContainerWrap">
                  <div className="imageWrap">
                    <img
                      src={
                        selectedArticlePreview
                          ? selectedArticlePreview?.image
                          : imageURL
                      }
                      alt=""
                    />
                  </div>
                </div>
              }
              className={"imagePreviewBodyWrap"}
            />
            <CustomModal
              onHide={() => {
                formik.resetForm();
                setImage(null);
                setImageURL("");
                setFileInputKey(Date.now());
                setItemId("");
                setEditData("");
                setEditDataImg("");
                setShowEditGosspel(false);
              }}
              show={showEditGosspel}
              heading="Edit the article"
              body={
                <form
                  className="title-heading "
                  id="editEmailForm"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="title-heading">
                    <div className="title2">Image</div>
                    <div>
                      <label
                        htmlFor="imageUploadInput"
                        className={`${
                          finalImageUrl
                            ? "image-upload-not-active"
                            : "image-upload"
                        }`}
                      >
                        <input
                          key={fileInputKey}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            formik.setFieldValue("image", e.target.files[0]);
                            handleImageUpload(e);
                          }}
                          onBlur={formik.handleBlur}
                          style={{ display: "none" }}
                          id="imageUploadInput"
                          disabled={finalImageUrl ? true : false}
                        />
                        <div
                          className={`${
                            finalImageUrl
                              ? "image-text-not-active"
                              : "image-text"
                          }`}
                        >
                          {finalImageUrl ? (
                            <UploadImage color="#747474" />
                          ) : (
                            <UploadImage />
                          )}
                          Tap here to upload an image
                        </div>
                      </label>
                      {formik.touched.image && formik.errors.image ? (
                        <div className="required-image-error">
                          {formik.errors.image}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {finalImageUrl && (
                    <div
                      className="image-view"
                      style={{ marginTop: "10px", width: "90px" }}
                    >
                      <img
                        src={finalImageUrl}
                        alt="Uploaded"
                        width="88px"
                        height="79px"
                        style={{ borderRadius: "8px" }}
                      />
                      <div
                        className="image-cross"
                        onClick={() => {
                          formik.setFieldValue("image", null);
                          setImage(null);
                          setImageURL("");
                          setEditDataImg("");
                          setFileInputKey(Date.now());
                        }}
                      >
                        <img
                          src={require("../../../assets/images/image-cross.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  <div className="title-heading ">
                    <Textfield
                      label="Title"
                      wordLimit={100}
                      placeholder="Enter title here..."
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      name="title"
                      rows={2}
                      maxLength={100}
                      error={formik.touched.title && !!formik.errors.title}
                      helperText={formik.errors.title}
                    />
                    <Textfield
                      label="Description"
                      wordLimit={4000}
                      placeholder="Enter description here..."
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      name="description"
                      rows={10}
                      maxLength={4000}
                      error={
                        formik.touched.description &&
                        !!formik.errors.description
                      }
                      helperText={formik.errors.description}
                    />
                  </div>
                </form>
              }
              footer={
                <div className="btn-gap">
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setImage(null);
                      setImageURL("");
                      setFileInputKey(Date.now());
                      setItemId("");
                      setEditData("");
                      setEditDataImg("");
                      setShowEditGosspel(false);
                    }}
                    variant="outline"
                    className="btn-black2"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-orange2"
                    type="submit"
                    form="editEmailForm"
                    disabled={!formik.dirty}
                  >
                    Save Changes
                  </Button>
                </div>
              }
            />
            <CustomModal
              show={showConfirmation}
              hideCross
              size="small"
              onHide={() => setShowConfirmation(false)}
              body={
                <div className=" text-center">
                  This will be visible to all the users. Are you sure you want
                  to publish?
                </div>
              }
              footer={
                <div className="btn-gap">
                  <Button
                    variant="outline"
                    className="btn-black2"
                    type="button"
                    onClick={() => {
                      setShowConfirmation(false);
                      setShowAddGosspel(true);
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    className="btn-orange2"
                    variant="unset"
                    onClick={AddGosselData}
                  >
                    Publish
                  </Button>
                </div>
              }
            />
            {showCropper && (
              <ImageCropper
                imageURL={imageURL}
                open={showCropper}
                onHide={() => {
                  setShowCropper(false);
                  setImage(null);
                  setImageURL("");
                  setFileInputKey(Date.now());
                  formik.setFieldValue("image", null);
                }}
                heading="Crop Your Image"
                close={() => {
                  setShowCropper(false);
                  setImage(null);
                  setImageURL("");
                  setFileInputKey(Date.now());
                  formik.setFieldValue("image", null);
                }}
                onCropCompleteCallback={handleCropComplete}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Gosspel;
