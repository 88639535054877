/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from "react";
import { useState } from "react";
import user from '../../../assets/images/icons8-user-30.png'
import pass from '../../../assets/images/icons8-password-30.png'
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../../layout/loader';
import { login } from './loginAction';
import { pushNotification } from "../../../utils/notification";
import { NOTIFICATION } from "../../../constants";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email Address')
        .required('Email is required'),
    password: Yup.string().required('Password is required').min(8),
});


const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const defaultFormData = useRef({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const loginResp = await dispatch(login(values));
            // await dispatch(profile());
            
            if(loginResp?.value?.status === 200 && loginResp?.value?.data?.data?.admin?.staticRole === 2){
                navigate(`/verify_user?page=${1}`, { state: { url: location.pathname }});
            }else if (loginResp?.value?.status === 200) {
                navigate('/');
            }
            pushNotification(loginResp?.value?.data?.message, NOTIFICATION.SUCCESS);
        } finally {
            setLoading(false);
            formik.setSubmitting(false);
        }
    };
    const formik = useFormik({
        initialValues: defaultFormData.current,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleClickShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <div className="login-page">
            <Loader promiseInProgress={isLoading} />
            <section className='login' id='login'>
                <img src={require('../../../assets/images/Logo.png')} alt="logo" className="login-logo" />
                <div className='head mb-2 mt-2'>
                    <h1 className='company'><b>UNLCKED ADMIN LOGIN</b></h1>
                </div>
                <div className='form'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <img src={user} className="mr-2" width={19} />
                            <input type="text" placeholder='Enter Email' className='text' name="email" value={formik.values.email} onChange={formik.handleChange} /><br />
                        </div>
                        <div className="mb-5">
                            <img src={pass} className="mr-2" width={19} />
                            <input type={showPassword ? 'text' : 'password'} name="password" placeholder='Enter Password' className={showPassword ? 'text' : 'password'} value={formik.values.password} onChange={formik.handleChange} />
                            <a onClick={handleClickShowPassword}>
                                {!showPassword ?
                                    <i className="fa fa-eye-slash" style={{ marginLeft: "-17px", cursor: "pointer", fontSize: "13px" }}></i> :
                                    <i className="fa fa-eye" style={{ marginLeft: "-17px", cursor: "pointer", fontSize: "13px" }}></i>
                                }
                            </a>
                            <br />
                        </div>
                        <button className='btn-login' id='do-login' type="submit">Login</button>
                    </form>
                </div>
            </section >
        </div>
    );

}

export default Login;
