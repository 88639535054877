import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import Loader from "../../../layout/loader";
import { getDeletedUsers } from "../../../services/deleteUsers.services";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";

const DontLikeApp = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const UserList = async (page) => {
    const reasonId = 5;
    setLoading(true);
    try {
      const { data: { data: { records = [], totalCount = 0 } = {} } = {} } =
        await getDeletedUsers(reasonId, page);
      setData(records);
      setFilteredData(records);
      setCount(totalCount);
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    UserList(page);
  }, [page]);

  useEffect(() => {
    if (search === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => {
        const fullName1 = `${item.firstName1} ${item.lastName1}`.toLowerCase();
        const fullName2 =
          `${item.firstName1} ${item.lastName1} & ${item.firstName2} ${item.lastName2}`.toLowerCase();
        return (
          fullName1.includes(search.toLowerCase()) ||
          fullName2.includes(search.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [search, data]);
  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="back-heading">
                  <div onClick={() => navigate("/deleted-users")}>
                    <img
                      src={
                        require("../../../assets/images/leftArrow.svg").default
                      }
                      alt=""
                      width="25px"
                    />
                  </div>
                  <h2 className="mb-0">DON'T LIKE THIS APP</h2>
                </div>
              </div>
              <div className="form-group has-search search-clear">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div
                className="table_box text-center"
                style={{ marginTop: "20px" }}
              >
                {filteredData.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USERNAME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.length > 0 &&
                        filteredData.map((item, index) => (
                          <tr key={index}>
                            <td>{(page - 1) * 10 + (index + 1)}</td>
                            <td>{item?.userName}</td>
                            <td>
                              {item.firstName2 === null
                                ? `${item?.firstName1} ${item?.lastName1}`
                                : `${item?.firstName1} ${item?.lastName1} & ${item?.firstName2} ${item?.lastName2}`}
                            </td>
                            <td>{item?.email}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && filteredData.length > 0 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(e) => setPage(e)}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DontLikeApp;
