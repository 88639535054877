import axios from "axios";
import { axiosInstance } from "../config/api";

export const getGosspelList = (page) => {
  let url = `gosspel?page=${page - 1 ?? 0}&limit=10`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteGosspel = (id) => {
  let url = `gosspel/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGosspelById = (id) => {
  let url = `gosspel/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGosspelComments = (id, page) => {
  let url = `gosspel/${id}/comments?page=${page - 1 ?? 0}&limit=10`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const AddArticle = (data) => {
  let url = "gosspel";
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const EditArticle = (id, data) => {
  let url = `gosspel/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const GetArticleImage = async (image) => {
  const imageType = image?.name.split(".").pop();
  let url = `image-upload/images/gosspel/${Date.now()}.${imageType}`;
  try {
    const response = await axiosInstance.get(url);
    const fileUploadData = response?.data?.data;

    if (fileUploadData) {
      await axios.put(fileUploadData.apiUrl, image, {
        headers: {
          "Content-Type": image.type,
        },
      });
    }
    return fileUploadData;
  } catch (err) {
    throw err;
  }
};

export const DeleteArticle = (id) => {
  let url = `gosspel/gosspel-comment/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const EmailDeletedGosspel = (id, data) => {
  let url = `gosspel/deleted-comment/send-email/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getGosspelEmailDataById = (id) => {
  let url = `gosspel/email-data/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};