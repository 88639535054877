import { axiosInstance } from '../../../config/api';
import { LOGIN, PROFILE } from './loginActionTypes';

export const login = (data) => ({
  type: LOGIN,
  payload: axiosInstance.post('auth/admin/signin', data),
});

export const profile = () => ({
  type: PROFILE,
  payload: axiosInstance.get('/admin/me'),
});
