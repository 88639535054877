import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSearchParams, useLocation } from "react-router-dom";
import Loader from "../../../layout/loader";
import { getAllSearch } from "../../../services/search.services";
import leftArrow from "../../../assets/images/leftArrow.svg";

const SearchPage = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();

  useEffect(() => {
    setPage(1);
  }, [query]);

  useEffect(() => {
    const search = query.get("search");
    if (query !== "" && query.get("search") !== "") {
      var searchData = search;
      SearchList(page, 10, searchData);
    }
  }, [query, location.pathname, page]);

  const SearchList = async (page, limit, search) => {
    toast.dismiss();
    setLoading(true);
    const response = await getAllSearch(page, limit, search);
    setData(response?.data?.data?.records);
    setCount(response?.data?.data?.totalCount);
    setLoading(false);
  };

  const handleNavigate = (pathTemp) => {
    navigate(pathTemp);
  };

  return (
    <section className="botmanagement_section">
      <Loader promiseInProgress={isLoading} />
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="sale_graph transaction_box mb-3">
              <div className="trans_head mb-3">
                <div className="btn_title_row">
                  <h2 className="mb-0 d-flex align-items-center">
                    <img
                      onClick={() => handleNavigate("/")}
                      src={leftArrow}
                      alt="leftArrow"
                      className="mr-2"
                    />{" "}
                    Search Results
                  </h2>
                </div>
              </div>
              <div className="table_box text-center">
                {data?.length > 0 && !isLoading ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>USERNAME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>STATUS</th>
                        <th>DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => (
                        <tr key={index}>
                          <td className={`${d?.isVisible ? '' : 'greyOutText'}`}>{(page - 1) * 10 + (index + 1)}</td>
                          <td className={`${d?.isVisible ? '' : 'greyOutText'}`}>{d?.userName ? d.userName : "-"}</td>
                          <td className={`${d?.isVisible ? '' : 'greyOutText'}`}>
                            {d?.firstName1
                              ? d?.firstName1 + " " + d?.lastName1
                              : d?.firstName1 && d?.firstName2
                              ? (d?.firstName1 + " " + d?.lastName1 + " ") &
                                (" " + d?.firstName2 + " " + d?.lastName2)
                              : "-"}
                          </td>
                          <td className={`${d?.isVisible ? '' : 'greyOutText'}`}>{d.email ? d.email : "-"}</td>
                          <td className={`${d?.isVisible ? '' : 'greyOutText'}`}>{d?.userProfileStatus}</td>
                          <td>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="btn-view-1">
                                  View User Details
                                </Tooltip>
                              }
                            >
                              <Button
                                onClick={() => {
                                  if(d?.userProfileStatus !== 'DELETED'){
                                    navigate(`/user_details/${d.id}`, {
                                      state: { url: location.pathname },
                                    });
                                  }
                                }}
                                className={`btn border-0 bg-transparent ${d?.userProfileStatus === 'DELETED' ? 'disabled' : ''}`}
                                variant="unset"
                              >
                                <img
                                  src={
                                    require("../../../assets/images/eye.svg")
                                      .default
                                  }
                                  alt=""
                                  width="25px"
                                />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="msg-data text-center py-3">
                    {!isLoading ? (
                      <h4>No Data Found</h4>
                    ) : (
                      <h6>Please wait...</h6>
                    )}
                  </div>
                )}
              </div>
              {!isLoading && (
                <>
                  {count > 10 && (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      prevPageText="Previous"
                      nextPageText="Next"
                      onChange={(event) => {
                        setPage(event || 1);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SearchPage;
