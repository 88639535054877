export const NOTIFICATION = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

// Errors
export const ERRORS = {
  NETWORK_ERROR: 'Network Error.',
  SESSION_EXPIRED_ERROR: 'Your session has expired please login again.',
};

export const ADMIN_PORTALS = ['www', 'admin', 'portal '];

export const SUB_DOMAIN_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/gim
);

export const NOT_VALID_SUB_DOMAIN_REGEX =
  /subdomain\s+"[\s\S]+"(?:\s+doesn't\s+exists+\s+or+\s+it\s+is\s+in-active)/;

export const NOT_ALLOWED_BLANK_SPACE_REGEX = /^(?! +$)[\s\S]+$/;

export const HEX_COLOR_CODE_REGEX = /^#([A-Fa-f0-9]{6})$/;

export const FILE_TYPE = [
  '.jpg',
  '.png',
  '.gif',
  '.jpeg',
  '.pdf',
  '.doc',
  '.docx',
];

export const IMAGE_TYPE = ['.jpg', '.png', '.gif', '.jpeg'];

export const EXCEL_EXTENSION = ['.xls', '.xlsx'];

export const ALLOWED_FILE_TYPE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
  'application/pdf',
];

export const ALLOWED_IMAGE_TYPE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
];

export const DOC_FILE_TYPE = [
  'application/msword',
  'application/doc',
  'application/ms-doc',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const EXCEL_FILE_TYPE = [
  'application/excel',
  'application/vnd.ms-excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const OPTIONS = {
  GENDER: [
    { id: '0', name: 'Female' },
    { id: '1', name: 'Male' },
    { id: '2', name: 'Others' },
  ],
  CLASSYEAR: [
    { id: 1, name: '1st Year' },
    { id: 2, name: '2nd Year' },
  ],
};

export const STUDENT_TEMPLATE_URL =
  `https://s3.ap-south-1.amazonaws.com/stcontent.bslearning.in/templates/StudentTemplate.xlsx?v=${Date.now()}`;

export const QUIZ_TEMPLATE_URL =
  `https://s3.ap-south-1.amazonaws.com/stcontent.bslearning.in/templates/QuizTemplate.xlsx?v=${Date.now()}`;

export const MAX_TIP_TITLE_LENGTH = 500;
export const MAX_DESCRIPTION_LENGTH = 5000;

export const DATE_LABELS = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  '29th',
  '30th',
  '31st',
];
