/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import logo from '../../../assets/images/Logo.png'
import { profile } from "../Login/loginAction";
import Loader from '../../../layout/loader';
import { changePassword, updateProfile } from "../../../services/admin.services";
import { commonLogoutFunc } from "../../../utils/share";
import { fileUpload } from "../../../services/file-upload.service";
import { useFormik } from "formik";
import * as Yup from "yup";

const Profile = () => {

    const [img, setImg] = useState({ file: '', url: '', key: '' })
    const [password, setPassword] = useState({ oldPwd: '', newPwd: '', newPwd2: '' })
    const [status, setStatus] = useState(true)
    const [show, setShow] = useState(false)
    const login = useSelector((state) => state?.login)
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(profile());
    }, [])

    useEffect(() => {
        const imgurl = login.user.profilePhoto
        if (imgurl) {
            const newUrl = imgurl.split('?')?.[0]?.split('.amazonaws.com/')?.[1]
            setImg({ file: '', url: imgurl, key: newUrl })
        }
    }, [login.user.profilePhoto])

    const handleImage = async (e) => {
        setLoading(true);
        const file = e?.target?.files?.[0];
        var regex = new RegExp("(.*?).(jpg|jpeg|png|PNG)$");
        if (!(regex.test(file?.name))) {
            Swal.fire(
                '',
                'Please select valid image file.',
                'warning'
            )
            return;
        }
        const imgResp = await fileUpload(file, file.name)
        console.log("imgResp", imgResp);
        setLoading(false);
        setImg({ file: file, url: imgResp.getUrl, key: imgResp.key })
    }

    const formik = useFormik({
        initialValues: {
            firstName: login?.user?.firstName || "",
            lastName: login?.user?.lastName || "",
            email: login?.user?.email || "",

        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please enter first name."),
            lastName: Yup.string().required("Please enter last name."),
            email: Yup.string().email("Please enter a valid email.").required("Please enter email."),
        }),
        onSubmit: (values) => {
            handleProfileUpdate(values);
        },
    });

    const handleProfileUpdate = async (updateProfileValues) => {
        setLoading(true)
        updateProfileValues.profilePhoto = img.key;
        try {
            setLoading(false)
            await updateProfile(updateProfileValues)
            toast.success('Profile Updated Successfully.')
        } catch (error) {
            setLoading(false)
            if (error.response.status === 401) {
                sessionStorage.clear()
                commonLogoutFunc()
                toast.error("Your session has been expired, Please login again!")
                return
            }
            toast.error(error.response.data.error)
        }
    }


    const updatePassword = async (e) => {
        toast.dismiss()
        if (password.oldPwd.trim() === '') {
            toast.error('Please enter old password.')
            return false
        }
        else if (password.newPwd.trim() === '') {
            toast.error('Please enter new password.')
            return false
        }
        else if (password.newPwd2.trim() === '') {
            toast.error('Please confirm new password.')
            return false
        }
        else if (password.newPwd !== password.newPwd2) {
            toast.error('New password and confirm password should be same.')
            return false
        }
        else if (password.oldPwd === password.newPwd) {
            toast.error("Old password and new password can't be same.")
            return false
        }
        else {
            setLoading(true);
            try {
                await changePassword({ "oldPassword": password.oldPwd, "newPassword": password.newPwd });
                setLoading(false);
                setShow(false);
                setPassword({ oldPwd: '', newPwd: '', newPwd2: '' });
                toast.success('Password updated successfully.');
            } catch (error) {
                setLoading(false);
                if (error.response.status === 401) {
                    sessionStorage.clear();
                    commonLogoutFunc();
                    toast.error("Your session has been expired, Please login again!");
                    return
                }
            }
        }
    }


    return (
        <section className="botmanagement_section edit_bot_details profile_section">
            <Loader promiseInProgress={login?.profile === "loading" || isLoading || !login.user} />

            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="sale_graph transaction_box mb-3">
                            <div className="trans_head pb-1">
                                <div>
                                    <h2 className="mb-1">Profile</h2>
                                </div>
                            </div>
                            <div className="details_box mt-2">
                                <Row>
                                    <Col md={4} className="text-center profilebtn">
                                        <div className="edit_bot_img m-auto">
                                            <img style={{ borderRadius: "50%" }} height="250" width="250" src={img.url ? img.url : logo} alt="" />
                                        </div>
                                        {!status &&
                                            <div className="profile-btn">
                                                <Button className="btn updatedbtn border-0" variant="unset">Change Profile Picture</Button>
                                                <input className="change-profile-input c-pointer" onChange={(e) => handleImage(e)} type="file" accept="image/jpg, image/jpeg, image/png" />
                                            </div>
                                        }
                                    </Col>
                                    <Col md={8}>
                                        <Row>
                                            <Col md={12}>
                                                <Form onSubmit={formik.handleSubmit}>
                                                    <Row>
                                                        <>{status && (
                                                            <Col md={12}>
                                                                <div className="text-right">
                                                                    <OverlayTrigger overlay={<Tooltip id="btn-sus-1">Edit Profile</Tooltip>}>
                                                                        <Button className="btn-orange" variant="unset" onClick={() => setStatus(false)}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </div>

                                                            </Col>
                                                        )}
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3 mt-2" controlId="formBasicFirst">
                                                                    <Form.Label>FIRST NAME:</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter First Name"
                                                                        {...formik.getFieldProps("firstName")}
                                                                        disabled={status}
                                                                        className={status ? "disabled-fields" : "input101"}
                                                                    />
                                                                    {formik.touched.firstName && formik.errors.firstName && (
                                                                        <div className="error">{formik.errors.firstName}</div>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3 mt-2" controlId="formBasicLast">
                                                                    <Form.Label>LAST NAME:</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter Last Name"
                                                                        {...formik.getFieldProps("lastName")}
                                                                        disabled={status}
                                                                        className={status ? "disabled-fields" : "input101"}
                                                                    />
                                                                    {formik.touched.lastName && formik.errors.lastName && (
                                                                        <div className="error">{formik.errors.lastName}</div>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                    <Form.Label>EMAIL:</Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        placeholder="Enter Email"
                                                                        {...formik.getFieldProps("email")}
                                                                        disabled={true}
                                                                    />
                                                                    {formik.touched.email && formik.errors.email && (
                                                                        <div className="error">{formik.errors.email}</div>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                        </>

                                                    </Row>

                                                    {!status && (
                                                        <Col md={12}>
                                                            <Button className="btn-orange my-3" variant="unset" type="submit">
                                                                Update
                                                            </Button>
                                                        </Col>
                                                    )}
                                                </Form>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={12}>
                                                {!show &&
                                                    < div className="text-right">
                                                        <Button className="btn-orange my-3" variant="unset" onClick={() => setShow(true)}>Change Password</Button>
                                                    </div>
                                                }
                                                {show &&
                                                    <Form>
                                                        <Row>
                                                            <Col md={12}>
                                                                <label className="my-3">CHANGE PASSWORD:</label>
                                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                    <Form.Control type="password" value={password.oldPwd} onChange={(e) => setPassword({ ...password, oldPwd: e.target.value })} placeholder="Enter Current Password" className="input100" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3 mt-2" controlId="formBasicNewPassword">
                                                                    <Form.Control type="password" value={password.newPwd} onChange={(e) => setPassword({ ...password, newPwd: e.target.value })} placeholder="Enter New Password" className="input100" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3 mt-2" controlId="formBasicNewPassword2">
                                                                    <Form.Control type="password" value={password.newPwd2} onChange={(e) => setPassword({ ...password, newPwd2: e.target.value })} placeholder="Confirm New Password" className="input100" />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Button onClick={(e) => updatePassword(e)} variant="unset" className="btn-orange my-3">
                                                                    Update
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        </section >
    );

}
export default Profile;
