import { combineReducers } from 'redux';
import { EMPTY_STORE_STATE, RESET_REDUX } from '../common/actionTypes';
import LoginReducer from '../components/Views/Login/loginReducer';

const appReducer = combineReducers({
  login: LoginReducer,
});

const rootReducer = (state, action) => {
  if (action.type === EMPTY_STORE_STATE) {
    state = {};
  }
  if (action.type === RESET_REDUX) {
    if (action.payload.reducerKey && action.payload.key) {
      const { reducerKey, key, value } = action.payload;
      state = {
        ...state,
        [reducerKey]: { ...state[reducerKey], [key]: value },
      };
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
